import React, { useState, useEffect } from "react";
import Banner from "../Banner/Banner";
import "./ClientPage.scss";
import { getClients } from "../Api/GetApi";
import { imgdomain } from "../Api/AxiosInstance";
import Demo from "../Demo/Demo";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

function ClientPage() {
  const [clientdata, setClientData] = useState([]);
  useEffect(() => {
    getClients().then((res) => {
      setClientData(res.data);
    });
  }, []);

  // window.scrollTo(0, 0);

  return (
    <div className="clientpage-section">
      <div className="banner-half">
        <Banner
          type="show"
          page="Clients"
          title="Our Valuable Clients"
          subTitle="NIVID School Management System"
        />
      </div>
      <div className="">
        <div className="">
          {clientdata.length && (
            <OwlCarousel
              className="owl-theme"
              // responsive={this.state.responsive}
              loop
              margin={10}
              items={2}
              nav
              // data-aos-once={true}
              // data-aos="fade-right"
            >
              {clientdata.map((item, index) => {
                return (
                  <div className="col-xl-4 col-lg-4 col-md-6  " key={index}>
                    <div
                      className="clientpage-content mgt-30 card-section d-flex"
                      data-aos-once={true}
                      data-aos="fade-right"
                    >
                      <img src={imgdomain + item.logo} alt="img" />
                      <div className="client-details  text-init ">
                        <h2 className="content">{item.school_name}</h2>
                        <p className="label-text">{item.address}</p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </OwlCarousel>
          )}
        </div>
      </div>
      <Demo
        title="We'd love to demonstrate what we can do for you"
        btn_text="Request Demo"
        btn_url="/demo"
      />
    </div>
  );
}

export default ClientPage;
