import React from "react";
import "./MobileApp.scss";
// import { Link } from "react-router-dom";

function MobileApp() {
  return (
    <div className="mobileapp-section">
      <div className="container">
        <div className="row">
          <div className="col-md-5">
            <div className="mobileapp-content ">
              <h2
                className="main-title text-init"
                data-aos-once={true}
                data-aos="fade-down"
              >
                Manage Your School Remotely
              </h2>
              <div
                className="content-style"
                data-aos-once={true}
                data-aos="fade-left"
              ></div>
              <div
                className="sub-content"
                data-aos-once={true}
                data-aos="fade-right"
              >
                <p className="content">
                  The following are the features of Nivid's mobile App:
                </p>
                <div className="d-flex ">
                  <ul>
                    <li>Teacher</li>
                    <li>Attendance</li>
                    <li>Mark Entry</li>
                    <li>View Assignment</li>
                    <li>Class Notice</li>
                  </ul>
                  <ul>
                    <li>Student</li>
                    <li>Upload Assignment</li>
                    <li>Due Payments</li>
                    <li>View Details</li>
                    <li>View Marks</li>
                  </ul>
                </div>
              </div>

              <div className="app-content">
                <h1
                  className="sub-content-text mgt-30"
                  data-aos-once={true}
                  data-aos="fade-up"
                >
                  Get the App
                </h1>
                <div
                  className="img-content"
                  data-aos-once={true}
                  data-aos="fade-up"
                >
                  <a
                    href="https://play.google.com/store/apps/details?id=com.nivid.nivid&hl=en&gl=US"
                    target="_blanks"
                  >
                    <img
                      className="img-mg"
                      src={require("../../images/GoogleplayBadge.png").default}
                      alt="img"
                    />
                  </a>
                  <a
                    href="https://apps.apple.com/lc/app/nivid/id1505281357"
                    target="_blanks"
                  >
                    <img
                      src={require("../../images/AppBadge.png").default}
                      alt="img"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-7">
            <img
              className="app-img"
              src={require("../../images/schoolapp.png").default}
              alt="img"
              data-aos-once={true}
              data-aos="fade-down"
            />

            <img
              className="appimg"
              src={require("../../images/schoolapp.png").default}
              alt="img"
              data-aos-once={true}
              data-aos="fade-up"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default MobileApp;
