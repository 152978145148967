import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getFaqs } from "../Api/GetApi";
import "./Accordian.scss";

export default function CustomizedAccordions(props) {
  const [faqData, setFaqData] = useState([]);
  // const [dataLength, setDataLength] = useState(4);
  const [sliceCount, setSliceCount] = useState(5);
  const [showButton, setShowButton] = useState(true);
  useEffect(() => {
    getFaqs().then((res) => {
      setFaqData(res.data);
      if (props?.isHomePage !== true) {
        setSliceCount(faqData.length);
        setShowButton(false);
      }
    });
  }, [faqData.length, props?.isHomePage]);
  return (
    <div className="newaccordian-section">
      <div className="container">
        <div class="m-4">
          <div class="accordion" id="myAccordion">
            {faqData?.slice(0, sliceCount).map((item, index) => {
              return (
                <div
                  class="accordion-item"
                  key={index}
                  data-aos-once={true}
                  data-aos="fade-right"
                >
                  <h2 class="accordion-header" id="headingOne">
                    <p
                      class="accordion-button collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target={`#collapse${index}`}
                    >
                      {item.question}
                    </p>
                  </h2>
                  <div
                    id={`collapse${index}`}
                    class="accordion-collapse collapse"
                    data-bs-parent="#myAccordion"
                  >
                    <div class="card-body">
                      <p className="mgl-3">{item.answer}</p>
                    </div>
                  </div>
                </div>
              );
            })}
            {faqData.length > 5 && showButton === true ? (
              <Link to="/faq">
                <button className="main-btn">See More</button>
              </Link>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
