const BLOOD_GROUP = ["A+", "A-", "B+", "B-", "O+", "O-", "AB+", "AB-"];
const GRADE = [
  { 
    label: "Grade 1",
    value: 1
  }, 
  { 
    label: "Grade 2",
    value: 2
  }, 
  { 
    label: "Grade 3",
    value: 3
  }, 
  { 
    label: "Grade 4",
    value: 4
  }, 
  { 
    label: "Grade 5",
    value: 5
  }, 
  { 
    label: "Grade 6",
    value: 6
  }, 
  { 
    label: "Grade 7",
    value: 7
  }, 
  { 
    label: "Grade 8",
    value: 8
  }, 
  { 
    label: "Grade 9",
    value: 9
  }, 
  { 
    label: "Grade 10",
    value: 10
  }, 
  { 
    label: "Grade 11",
    value: 11
  }, 
  { 
    label: "Grade 12",
    value: 12
  }, 
];
const COUNTRY = ["Nepali", "Indian", " Japanese", "Chinese", "Others"];
const RELIGION = ["Hindu", "Buddhist", "Muslim", "Kirant/Yum", "Christian", "Others"];
const CASTE = [
  "Chettri",
  "Brahmins",
  "Magar",
  "Tharu",
  "Tamang",
  "Newar",
  "Kami",
  "Nepalese Muslims",
  "Yadav",
  "Rai",
  "Gurung",
  "Damai",
  "Thakuri",
  "Limbu",
  "Sarki",
  "Sharma",
  "Charmar",
  "Thakur",
  "Madhesi",
  "Other Janajati/Tibetan sherpa",
  "Other Khas tribes",
  "Others",
];
const OCCUPATION = [
  "Teacher",
  "Banker",
  "Farmer",
  "Business",
  "Plumber",
  "Journalist",
  "Butcher",
  "Mechanic",
  "Lawyer",
  "Singer",
  "Others",
];
const GENDER = ["Male", "Female", " Others"];
const RELATIONSHIP = ["Brother", "Sister", "Uncle", "Aunt", "Cousin", "Grandfather", "Grandmother"];

export { BLOOD_GROUP, GRADE, COUNTRY, RELIGION, CASTE, OCCUPATION, GENDER, RELATIONSHIP };
