import React from 'react';
import "./Infocards.css"

const Infocards = (props) => {
    const createMarkup = (description) => ({__html: description});

  return (
        <div className="infocard-items"
        data-aos-once={true}
        data-aos="fade-right">
            <div className='infocard-content'>
                <div>{props.img}</div>
                <div className='infocard-grid'>
                <h1> {props.title}</h1>    
                <span> </span>        
                         <p
                dangerouslySetInnerHTML={createMarkup(props.description|| "")}/>
            </div>
            </div>
        </div>
  )
}

export default Infocards