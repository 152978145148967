import React from "react";
import "./Footer.scss";
import { Link } from "react-router-dom";

function Footer() {
  
  return (
    <div className="footer-section section-top">
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-lg-10 text-init">
            <p className="footer-title">
              <img
                src={require("../../images/nivid-logo-min.png").default}
                alt="img"
              />
              School Management System
            </p>
            {/* <div className="text-center"> */}
            <div className="row">
              <div className="col-lg-2 col-md-3 col-sm-4 ">
                <Link to="/">
                  <p
                    className="content"
                  >
                    Home
                  </p>
                </Link>
              </div>
              <div className="col-lg-2 col-md-3 col-sm-4">
                <Link to="/about-us">
                  <p className="content">About us</p>
                </Link>
              </div>
              <div className="col-lg-2 col-md-3 col-sm-4">
                <Link to="/features">
                  <p className="content">Features</p>
                </Link>
              </div>
              <div className="col-lg-2 col-md-3 col-sm-4">
                <Link to="/our-clients">
                  <p className="content">Our Clients</p>
                </Link>
              </div>
              {/* <div className="col-lg-2 col-md-3 col-sm-3">
                  <Link to="/">
                    <p className="content">Testimonals</p>
                  </Link>
                </div> */}
              <div className="col-lg-2 col-md-3 col-sm-4">
                <Link to="contactus">
                  <p className="content">Contact Us</p>
                </Link>
              </div>
              <div className="col-lg-2 col-md-3 col-sm-4">
                <Link to="/privacy">
                  <p className="content">Privacy Policy</p>
                </Link>
              </div>
            </div>
            {/* </div> */}
            <p className="content">
              Ⓒ 2022 NIVID School Management System, All rights reserved
            </p>
          </div>
          <div className="col-md-12 col-lg-2 footer-app">
            <div className="app-content">
              <h1 className="sub-content-text mt-10">Get the App</h1>
              <div className="img-content">
                <a
                  href="https://play.google.com/store/apps/details?id=com.nivid.nivid&hl=en&gl=US"
                  target="_blanks"
                >
                  <img
                    className="img-mg"
                    src={require("../../images/GoogleplayBadge.png").default}
                    alt="img"
                  />
                </a>
                <a
                  href="https://apps.apple.com/lc/app/nivid/id1505281357"
                  target="_blanks"
                >
                  <img
                    src={require("../../images/AppBadge.png").default}
                    alt="img"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
