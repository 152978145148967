import React, { useState, useEffect } from "react";
import "./Clients.scss";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { getClients } from "../Api/GetApi";
import { imgdomain } from "../Api/AxiosInstance";
function Clients() {
  const [clientdata, setClientData] = useState([]);
  const responsive = {
    0: {
      items: 8,
    },
    360: {
      items: 2,
    },
    480: {
      items: 2,
    },
    600: {
      items: 4,
    },
    1000: {
      items: 8,
    },
  };
  useEffect(() => {
    getClients().then((res) => {
      setClientData(res.data);
    });
  }, []);
  // window.scrollTo(0, 0);

  return (
    <div className="client-section ">
      <div className="container">
        <div className="alignCenter">
          {clientdata?.length !== 0 && (
            <OwlCarousel
              className="owl-theme"
              responsive={responsive}
              loop
              autoplay="true"
              autoplayTimeout="1000"
              autoplayHoverPause="true"
              margin={10}
              items={8}
              nav
              data-aos-once={true}
              data-aos="fade-right"
            >
              {clientdata?.map((item, index) => {
                return (
                  <div
                    className="custom-card"
                    data-aos-once={true}
                    data-aos="fade-left"
                    key={index}
                  >
                    <img
                      src={imgdomain + item.logo}
                      alt="img"
                      className="img-width"
                    />
                  </div>
                );
              })}
            </OwlCarousel>
          )}
        </div>
      </div>
    </div>
  );
}

export default Clients;
