// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDsagX2nCTxs8S9N-UOzQF9efHP4eCAU0w",
  authDomain: "nividwebsite-analytics.firebaseapp.com",
  projectId: "nividwebsite-analytics",
  storageBucket: "nividwebsite-analytics.appspot.com",
  messagingSenderId: "695201696065",
  appId: "1:695201696065:web:ed74a1521352aa65c9b9c0",
  measurementId: "G-Z6VTP1PPS1",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export function logAnalyticEvent(eventName) {
  logEvent(analytics, eventName);
  console.log("fired analytic log");
}

export function snakeCase(string) {
  return string
    .replace(/\W+/g, " ")
    .split(/ |\B(?=[A-Z])/)
    .map((word) => word.toLowerCase())
    .join("_");
}
