import React from "react";
import { OCCUPATION, RELATIONSHIP } from "../../utils/constant";
import AdimssionFormHead from "../AdmissionForm/AdmissionFormHead/AdimssionFormHead";
import { useAdmissionFormFormikContext } from "../HorizontalStepper.js/FormikWrapper";
import "./ParentInfo.scss";

export const ParentsInfo = ({ schoolName, address, logo, email, contact }) => {
  const { getFieldProps, setFieldValue , values } = useAdmissionFormFormikContext();

  return (
    <div className="parents-info">
      <div className="section-gap">
        <div className="container">
          <div className="admission-form">
            <AdimssionFormHead
              schoolName={schoolName}
              address={address}
              logo={logo}
              email={email}
              contact={contact}
            />
            <div className="option-head">
              {/* <div className="field-input-block">
                    <p className="main-content uppercase">
                      Grade Applied For<span className="star">*</span>:
                    </p>
                    <select className="field-input">
                      <option>--Select--</option>
                      {["Grade 1", "Grade 2", "Grade 3"]?.map((item) => {
                        return <option key={item}>{item}</option>;
                      })}
                    </select>
                  </div> */}
              <div className="uppercase small-title center">
                Parent's Information
              </div>
            </div>
            <div className="fathers-detail">
              <div className="uppercase medium-content blue-text">
                Father's Information
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="field-input-block">
                    <p className="main-content name-tag">Father's Name:</p>
                    <input
                      className="field-input"
                      name="father_name"
                      onChange={(e) => {
                        setFieldValue("father_name", e.target.value);
                      }}
                      {...getFieldProps("father_name")}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="field-input-block">
                    <p className="main-content name-tag">Email:</p>
                    <input
                      className="field-input"
                      name="father_email"
                      onChange={(e) => {
                        setFieldValue("father_email", e.target.value);
                      }}
                      {...getFieldProps("father_email")}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="field-input-block">
                    <p className="main-content name-tag">Occupation:</p>
                    <select
                      className="field-input"
                      onChange={(e) => {
                        setFieldValue("occupation".e.target.value);
                      }}
                      {...getFieldProps("occupation")}
                    >
                      <option value="" disabled>
                        --Select--
                      </option>
                      {OCCUPATION?.map((item) => {
                        return (
                          <option key={item} name="occupation">
                            {item}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="field-input-block">
                    <p className="main-content name-tag">Mobile No.:</p>
                    <input
                      className="field-input"
                      type="number"
                      name="father_number"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onChange={(e) => {
                        setFieldValue("father_number", e.target.value);
                      }}
                      {...getFieldProps("father_number")}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="field-input-block">
                    <p className="main-content name-tag">Post:</p>
                    <input
                      className="field-input"
                      name="fpost"
                      onChange={(e) => {
                        setFieldValue("fpost", e.target.value);
                      }}
                      {...getFieldProps("fpost")}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="field-input-block">
                    <p className="main-content name-tag">Office:</p>
                    <input
                      className="field-input"
                      name="father_office"
                      onChange={(e) => {
                        setFieldValue("father_office", e.target.value);
                      }}
                      {...getFieldProps("father_office")}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="field-input-block">
                    <p className="main-content name-tag">Telephone(Office):</p>
                    <input
                      className="field-input"
                      type="number"
                      name="father_office_number"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onChange={(e) => {
                        setFieldValue("father_office_number", e.target.value);
                      }}
                      {...getFieldProps("father_office_number")}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="fathers-detail">
              <div className="uppercase medium-content blue-text">
                Mother's Information
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="field-input-block">
                    <p className="main-content name-tag">Mother's Name:</p>
                    <input
                      className="field-input"
                      name="mother_name"
                      onChange={(e) => {
                        setFieldValue("mother_name");
                      }}
                      {...getFieldProps("mother_name")}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="field-input-block">
                    <p className="main-content name-tag">Email:</p>
                    <input
                      className="field-input"
                      name="mother_email"
                      onChange={(e) => {
                        setFieldValue("mother_email", e.target.value);
                      }}
                      {...getFieldProps("mother_email")}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="field-input-block">
                    <p className="main-content name-tag">Occupation:</p>
                    <select
                      className="field-input"
                      onChange={(e) => {
                        setFieldValue("mother_occupation", e.target.value);
                      }}
                      {...getFieldProps("mother_occupation")}
                    >
                      <option value="" disabled>
                        --Select--
                      </option>
                      {OCCUPATION?.map((item) => {
                        return (
                          <option key={item} name="mother_occupation">
                            {item}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="field-input-block">
                    <p className="main-content name-tag">Mobile No.:</p>
                    <input
                      className="field-input"
                      type="number"
                      name="mother_number"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onChange={(e) => {
                        setFieldValue("mother_number", e.target.value);
                      }}
                      value={values.mother_number}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="field-input-block">
                    <p className="main-content name-tag">Post:</p>
                    <input
                      className="field-input"
                      name="mother_post"
                      onChange={(e) => {
                        setFieldValue("mother_post", e.target.value);
                      }}
                      {...getFieldProps("mother_post")}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="field-input-block">
                    <p className="main-content name-tag">Office:</p>
                    <input
                      className="field-input"
                      name="mother_office"
                      onChange={(e) => {
                        setFieldValue("mother_office", e.target.value);
                      }}
                      {...getFieldProps("mother_office")}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="field-input-block">
                    <p className="main-content name-tag">Telephone(Office):</p>
                    <input
                      className="field-input"
                      type="number"
                      name="mother_office_number"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onChange={(e) => {
                        setFieldValue("mother_office_number", e.target.value);
                      }}
                      {...getFieldProps("mother_office_number")}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="fathers-detail">
              <div className="uppercase medium-content blue-text">
                Guardian's Information
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="field-input-block">
                    <p className="main-content name-tag">
                      Local Guardian's Name:
                    </p>
                    <input
                      className="field-input"
                      name="guardian_name"
                      onChange={(e) => {
                        setFieldValue("guardian_name", e.target.value);
                      }}
                      {...getFieldProps("guardian_name")}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="field-input-block">
                    <p className="main-content name-tag">Address:</p>
                    <input
                      className="field-input"
                      name="gaddress"
                      onChange={(e) => {
                        setFieldValue("gaddress", e.target.value);
                      }}
                      {...getFieldProps("gaddress")}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="field-input-block">
                    <p className="main-content name-tag">Relationship:</p>
                    <select
                      className="field-input"
                      onChange={(e) => {
                        setFieldValue("realtion", e.target.value);
                      }}
                      {...getFieldProps("realtion")}
                    >
                      <option value="" disabled>
                        --Select--
                      </option>
                      {RELATIONSHIP?.map((item) => {
                        return (
                          <option key={item} name="realtion">
                            {item}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="field-input-block">
                    <p className="main-content name-tag">Occupation:</p>
                    <select
                      className="field-input"
                      onChange={(e) => {
                        setFieldValue("guardian_occupation", e.target.value);
                      }}
                      {...getFieldProps("guardian_occupation")}
                    >
                      <option value="" disabled>
                        --Select--
                      </option>
                      {OCCUPATION?.map((item) => {
                        return (
                          <option key={item} name="guardian_occupation">
                            {item}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="field-input-block">
                    <p className="main-content name-tag">Mobile No.:</p>
                    <input
                      className="field-input"
                      type="number"
                      name="guardian_number"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onChange={(e) => {
                        setFieldValue("guardian_number", e.target.value);
                      }}
                      {...getFieldProps("guardian_number")}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="field-input-block">
                    <p className="main-content name-tag">Telephone(Home):</p>
                    <input
                      className="field-input"
                      type="number"
                      name="gtel"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onChange={(e) => {
                        setFieldValue("guardian_phone", e.target.value);
                      }}
                      {...getFieldProps("guardian_phone")}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
