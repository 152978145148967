import React from "react";
import "../../PersonalInfo/PersonalInfo.scss";

const AdimssionFormHead = ({ schoolName, address, logo, email, contact }) => {
  return (
    <>
      <div className="personal-info">
        <div className="section-gap">
          <div className="container">
            <div className="admission-form">
            </div>
              <div style={{display: "flex", justifyContent: "center"}}>
                <div style={{width: "300px"}}>
                <img src={logo} alt="img" height={"200px"} width={"200px"} style={{objectFit: "contain"}} />
                </div>
                <div className="admission-header">
                  <div>
                    <h4 className="main-title school-name">
                      {schoolName}
                    </h4>
                    <div className="address-wrapper d-column">
                      <div className="address-info">
                        <p className="main-content address">{address}</p>
                      </div>
                      <div className="address-info">
                        <p className="main-content phone-no">{contact}</p>
                        <span className="side-bar"></span>
                        <p className="main-content email">{email}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            
          </div>
        </div>
      </div>
    </>
  );
};

export default AdimssionFormHead;
