import html2pdf from "html2pdf.js";

export const handlePdfDownload = ({ printRef }) => {
  const element = printRef.current;
  const options = {
    filename: "document.pdf",
    image: { type: "jpeg", quality: 0.98 },
    margin: [0.6, 0, 0.6, 0],
    html2canvas: {
      scale: 2,
      dpi: 192,
      logging: true,
      letterRendering: true,
      useCORS: true,
    },
    jsPDF: {
      unit: "in",
      format: "a4",
      orientation: "portrait",
      borderColor: "#219ebc", // border color
      borderWidth: 1,
    },
    pagebreak: { mode: "avoid-all", before: ".page-break" },
  };
  html2pdf().from(element).set(options).save();
};
