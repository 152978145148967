import Axios from "../Api/AxiosInstance";

export const Contactform = (data) => {
  return Axios.post("contactus", data);
};

export const Demorequest = (data) => {
  return Axios.post("demorequest", data);
};

export const PostAdmission = (data) => {
  return Axios.post("axios/admission_form ",
   data,  {
    headers: {
      "content-type": "multipart/form-data",
    },
   });
};
