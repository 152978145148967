import React from "react";
import "./AboutDetails.scss";

function AboutDetails() {
  return (
    <div className="aboutdetails-section ">
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-lg-8">
            <div className="details-content">
              <p className="content">
                NIVID is a School Management Software (SMS) developed by Axios
                Softwork Pvt. Ltd. We, being an emerging Nepalese IT company,
                aim to make optimum use of technology so as to digitize and
                automate the systems for the overall progress of the country. It
                digitizes all the operations making them easily accessible,
                retrievable, and interpretable.
              </p>
              <p className="content">
                Within a short span of time, we have successfully digitalized
                more than 200+ schools/colleges across the country. We have not
                only made swift progress, but have also gained credibility among
                the schools and colleges.
              </p>
              <p className="content">
                We humbly claim NIVID to be the first company in Nepal to
                provide the top-notch School Management System and services to
                educational institutions.
              </p>
            </div>
          </div>

          <div className="col-md-12 col-lg-4">
            <img
              src={require("../../images/aboutdetails.png").default}
              alt="img"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutDetails;
