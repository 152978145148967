import React, { useEffect } from "react";
import "./Banner.scss";
import { Link } from "react-router-dom";

function Banner(props) {
  return (
    <div className="banner-section">
      <div className="banner-bg">
        <img
          className="bg-img"
          src={require("../../images/banner.png").default}
          alt="images"
        />
        <div className="container">
          <div
            className="banner-content"
            data-aos-once={true}
            data-aos="fade-right"
          >
            <h1 className="main-title">{props.title}</h1>
            {props.home === "true" ? "" : <div className="content-style"></div>}

            <p>{props.subTitle}</p>

            <Link to="demo">
              <button className="main-btn">Request a free demo</button>
            </Link>
          </div>
        </div>
      </div>
      <img
        className="app-img"
        src={require("../../images/schoolapp.png").default}
        alt="images"
        data-aos-once={true}
        data-aos="fade-left"
      />
    </div>
  );
}

export default Banner;
