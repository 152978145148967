import React from "react";
import Banner from "../../components/Banner/Banner";
import MetaConfigure from "../../components/helmet/helmet";
import PricacyPolicyComponent from "../../components/PrivacyPolicy/PrivacyPolicyComponent";
const PrivacyPolicy = () => {
  // window.scrollTo(0, 0);
  return (
    <>
      <div className="banner-half">
        <MetaConfigure title="Privacy-Nivid" />

        <Banner
          type="show"
          page="Privacy Policy"
          title="Privacy Policy"
          subTitle="NIVID Privacy Policies"
        />
      </div>
      <PricacyPolicyComponent />
    </>
  );
};
export default PrivacyPolicy;
