import React, { useEffect, useState } from "react";
import { logAnalyticEvent, snakeCase } from "../../firebaseconfig";
import "./RelatedFeature.scss";
import { getFeatures } from "../Api/GetApi";
import { imgdomain } from "../Api/AxiosInstance";
import { Link } from "react-router-dom";

function RelatedFeatures(props) {
  const [featuresdata, setFeaturesData] = useState([]);
  // const [dataLength, setDataLength] = useState(6);
  useEffect(() => {
    getFeatures().then((res) => {
      let data = props.isHome ? res.data.slice(0, 6) : res.data;

      setFeaturesData(data);
    });

    // if (props.isHome !== true) {
    //   setDataLength(featuresdata.length);
    // }
  }, [props.isHome]);

  return (
    <div className="feature-section section-top">
      <div className="container">
        {props.type === "featureAll" ? (
          ""
        ) : (
          <>
            <h2 className="main-title">Meet exciting features of app</h2>
            <div className="content-style"></div>
          </>
        )}

        <div className="row">
          {featuresdata?.map((item, index) => {
            return (
              <div className="col-lg-4 col-md-6">
                <div className="feature-content" key={index}>
                  <img src={imgdomain + item.banner} alt="img" />
                  <div className="sub-content">
                    <h1 className="title-content">{item.title}</h1>
                    <p className="content">{item.desc}</p>

                    <div className="d-flex">
                      <a
                        href="subFeature"
                        onClick={() =>
                          logAnalyticEvent(
                            snakeCase("General Module") + "_clicked"
                          )
                        }
                      >
                        {" "}
                        <Link to={`/features/${item.slug}`}>
                          <p className="link-content">Learn more</p>
                        </Link>
                      </a>
                      <img
                        src={require("../../images/icons/arrow.svg").default}
                        alt="img"
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        {props.type === "featureAll" ? (
          ""
        ) : (
          <Link to="features">
            <button className="main-btn mgt-30">Show More</button>
          </Link>
        )}
      </div>
    </div>
  );
}

export default RelatedFeatures;
