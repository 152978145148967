import React, { useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import "./InfoBody.css";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import Infocards from "./InfoCards/Infocards";
import missionicon from "../../../../images/icons/mission.svg";
import visionicoon from "../../../../images/icons/vision.svg";
import objicon from "../../../../images/icons/obj.svg";
import { getSchoolInfo } from "../../../Api/GetApi";
import { HiChevronDown } from "react-icons/hi";
import { HiChevronUp } from "react-icons/hi";
import Schoolcardslider from "./SchoolCard/SchoolCardSlide/Schoolcardslider";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const InfoBody = ({
  schoolName,
  id,
  setEmail,
  setContact,
  setReletedData,
  schooldata,
}) => {
  const [items, setItems] = useState(4);
  const [showAll, setShowAll] = useState(false);

  const [brochureitems, setBrochureItems] = useState(4);
  const [showbrochure, setShowBrochure] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const [imgIndex, setImgIndex] = useState(4);

  const [isBrocOpen, setIsBrosOpen] = useState(false);
  const [brochureimgIndex, setBrochureImgIndex] = useState(0);

  const loadMore = () => {
    setItems(items + 4);
  };

  const loadLess = () => {
    setItems(4);
    setShowAll(false);
  };

  const brochuremore = () => {
    setBrochureItems(brochureitems + 4);
  };

  const brochureless = () => {
    setBrochureItems(4);
    setShowBrochure(false);
  };

  const galleryslice = useMemo(() => {
    return schooldata?.gallery?.slice(0, items);
  }, [schooldata?.gallery, items]);

  const brochureslice = useMemo(() => {
    return schooldata?.brochure?.slice(0, brochureitems);
  }, [schooldata?.brochure, brochureitems]);

  const createMarkup = (description) => ({ __html: description });

  const CardItems = [
    {
      img: <img src={missionicon} alt="missionicon" className="missionicon" />,
      Title: "Mission",
      description: `${schooldata?.mission}`,
    },
    {
      img: <img src={visionicoon} alt="missionicon" className="visionicon" />,
      Title: "Vision",
      description: `${schooldata?.vision}`,
    },
    {
      img: <img src={objicon} alt="missionicon" className="objicon" />,
      Title: "Objective",
      description: `${schooldata?.objective}`,
    },
  ];

  // const schoolContact = schooldata?.contact;
  const reletedschool = schooldata?.related_schools;

  // setEmail(schoolEmail);

  const responsive = {
    0: {
      items: 8,
    },
    360: {
      items: 2,
    },
    480: {
      items: 3,
    },
    768: {
      items: 4,
    },
  };

  return (
    <>
      <div className="school_container">
        <div className="school_info">
          <div
            className="intro-headers"
            data-aos-once={true}
            data-aos="fade-down"
          >
            <label>Introduction</label>
            <h4 className="introduction"></h4>
          </div>

          <div
            className="info_contents"
            data-aos-once={true}
            data-aos="fade-right"
          >
            <p
              dangerouslySetInnerHTML={createMarkup(
                schooldata?.introduction || ""
              )}
            />
            {schooldata?.school_image ? (
              <img
                className="schoolimage"
                src={schooldata?.school_image}
                alt="school_image "
                // onError={(this.style.display = "none")}
              />
            ) : (
              <p className="fs-4 ms-5">Not Available</p>
            )}
          </div>

          <div className="feature_message">
            <label data-aos-once={true} data-aos="fade-down">
              Features
            </label>
            <h4
              data-aos-once={true}
              data-aos="fade-down"
              className="features"
            ></h4>
            <p
              data-aos-once={true}
              data-aos="fade-right"
              dangerouslySetInnerHTML={createMarkup(schooldata?.features || "")}
            />
          </div>
              {schooldata?.principal_message !== "" &&
          <div className="principle_contents">
            <label data-aos-once={true} data-aos="fade-down">
              Principal Message
            </label>
            <h4
              data-aos-once={true}
              data-aos="fade-down"
              className="principle"
            ></h4>
            <div
              className="principal_message"
              data-aos-once={true}
              data-aos="fade-right"
            >
              <p
                dangerouslySetInnerHTML={createMarkup(
                  schooldata?.principal_message || ""
                )}
              />
              {schooldata?.principal_image ? (
                <img
                  className="principleimage"
                  src={schooldata?.principal_image}
                  alt="Principal_image"
                />
              ) : (
               ""
              )}
            </div>
          </div>}

          <div className="infobody-card">
            <div
              className="info-title"
              data-aos-once={true}
              data-aos="fade-down"
            >
              <label>Mission, Vision, Objectives</label>
              <h4 className="mission"></h4>
            </div>
            {CardItems?.map((items) => {
              return (
                <Infocards
                  img={items.img}
                  title={items.Title}
                  description={items.description}
                />
              );
            })}
          </div>

          <div className="why-us">
            <div
              className="whyus-title"
              data-aos-once={true}
              data-aos="fade-down"
            >
              <label>Why us?</label>
              <h4 className="why"></h4>
            </div>
            <p dangerouslySetInnerHTML={createMarkup(schooldata?.why || "")} />
          </div>

          <div className="brochures-content ">
            {/* brochure items */}
            <div
              className="brochure-head"
              data-aos-once={true}
              data-aos="fade-down"
            >
              <label>Brochure </label>
              <h4 className="brochures"></h4>
            </div>

            <div
              className="brochure"
              data-aos-once={true}
              data-aos="fade-right"
            >
              {brochureslice?.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt="gallery"
                  onClick={() => setIsBrosOpen(true)}
                />
              ))}
              {isBrocOpen && (
                <Lightbox
                  imageTitle={`${schoolName}'s Gallery`}
                  mainSrc={brochureslice?.[brochureimgIndex]}
                  nextSrc={
                    brochureslice?.[
                      (brochureimgIndex + 1) % brochureslice?.length
                    ]
                  }
                  prevSrc={
                    brochureslice?.[
                      (brochureimgIndex + brochureslice?.length - 1) %
                        brochureslice?.length
                    ]
                  }
                  onCloseRequest={() => setIsBrosOpen(false)}
                  onMovePrevRequest={() =>
                    setBrochureImgIndex(
                      (brochureimgIndex + brochureslice?.length - 1) %
                        brochureslice?.length
                    )
                  }
                  onMoveNextRequest={() =>
                    setBrochureImgIndex(
                      (brochureimgIndex + 1) % brochureslice?.length
                    )
                  }
                />
              )}
            </div>
          </div>

          <div className="showbutton">
            {brochureitems < schooldata?.brochure?.length && (
              <h6 className="showmore" onClick={brochuremore}>
                View more <HiChevronDown size={20} />
              </h6>
            )}

            {brochureitems > 4 && (
              <h6 className="showless" onClick={brochureless}>
                View less <HiChevronUp size={20} />
              </h6>
            )}
          </div>

          <div className="gallary-contents">
            {/* gallery items */}
            <div
              data-aos-once={true}
              data-aos="fade-down"
              className="gallery-head"
            >
              <label>Gallery</label>
              <h4 className="gallarys"></h4>
            </div>
            <div className="gallary" data-aos-once={true} data-aos="fade-right">
              {galleryslice?.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt="gallery"
                  onClick={() => setIsOpen(true)}
                />
              ))}

              {isOpen && (
                <Lightbox
                  imageTitle={`${schoolName}'s Gallery`}
                  mainSrc={galleryslice?.[imgIndex]}
                  nextSrc={
                    galleryslice?.[(imgIndex + 1) % galleryslice?.length]
                  }
                  prevSrc={
                    galleryslice?.[
                      (imgIndex + galleryslice?.length - 1) %
                        galleryslice?.length
                    ]
                  }
                  onCloseRequest={() => setIsOpen(false)}
                  onMovePrevRequest={() =>
                    setImgIndex(
                      (imgIndex + galleryslice?.length - 1) %
                        galleryslice?.length
                    )
                  }
                  onMoveNextRequest={() =>
                    setImgIndex((imgIndex + 1) % galleryslice?.length)
                  }
                />
              )}
            </div>
          </div>

          {/* showbutton for gallery */}
          <div className="showbutton">
            {items < schooldata?.gallery?.length && (
              <h6 className="showmore" onClick={loadMore}>
                View more <HiChevronDown size={20} />
              </h6>
            )}

            {items > 4 && (
              <h6 className="showless" onClick={loadLess}>
                View less <HiChevronUp size={20} />
              </h6>
            )}
          </div>
        </div>
      </div>
      <div className="slider-title" data-aos-once={true} data-aos="fade-down">
        <label>Related School</label>
        <h4> </h4>
      </div>
      <div className="sliderr-container">
        <div className="sliderr">
          <OwlCarousel
            className="owl-theme"
            loop
            responsive={responsive}
            autoplay="true"
            autoplayTimeout="1000"
            autoplayHoverPause="true"
            margin={10}
            items={8}
            nav
            data-aos-once={true}
            data-aos="fade-right"
          >
            {reletedschool?.map((items) => {
              return (
                <Schoolcardslider
                  image={items.logo}
                  name={items.school_name}
                  address={items.address}
                />
              );
            })}
          </OwlCarousel>
          {/* )} */}
        </div>
      </div>
    </>
  );
};

export default InfoBody;
