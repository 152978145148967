import React from "react";
import "./TermOfUseComponent.scss"
const TermOfUseComponent = () => {
    return(
        <div className="term-of-use-section ">
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                <h2 className="mb-30">Terms of use</h2>
                <h4 className="mb-10">IMPORTANT READ CAREFULLY:</h4>
                <h5>YOUR USE OF AND ACCESS TO THE SERVICE (AND ASSOCIATED SOFTWARE) (COLLECTIVELY, THE“SERVICE”) OF AXIOS SOFTWORK PVT. LTD. IS CONDITIONED UPON YOUR COMPLIANCE WITH AND ACCEPTANCE OF THESE TERMS. PLEASE REVIEW THOROUGHLY BEFORE ACCEPTING.</h5>
                <p>Axios will provide the Service, and you may access and use the Service, in accordance with this Agreement.</p>
                
                    <h5>1. THE SERVICE</h5>
                    <p>1.1 The Service is a cloud-based School Management System ( NIVID ).It makes overall activities of the school organized, smooth, and systematic. It is designed to digitize all the operations making them easily accessible, retrievable, and interpretable.
                    </p>
                    <p>1.2 Axios provides you a right and permission to use the Service subject to a valid subscription as further detailed below.
                    </p>
                    <p>1.3 Axios  uses commercially reasonable efforts to maintain the highest Service availability. However, Axios cannot guarantee that the Service will operate in an uninterrupted or error-free manner. Axios performs Service maintenance and uses commercially reasonable effort to schedule system down-time to off-peak hours and to avoid service interruptions and delays.
                    </p>

                    <h5>2. EVALUATION PERIOD</h5>
                    <p>
                    Axios may make the Service or any part of it available to you after your purchase of a Service subscription, or any termination of the evaluation by Axios for any reason, or for no reason at all, by sending you a termination notice with immediate effect.
                    </p>

                    <h5>3. DATA SECURITY</h5>
                    <p>
                     3.1 Axios will maintain reasonable physical and technical safeguards to prevent unauthorized disclosure of or access to Content, in accordance with industry standards. Axios will notify you if it becomes aware of unauthorized access to Content. Axios will not access, view or process Content except (a) as provided for in this Agreement and in Axios’s privacy policy (“Privacy Policy”); (b) as authorized or instructed by you, (c) as required to perform its obligations under this Agreement; or (d) as required by applicable law. Axios has no other obligations with respect to Content.</p>
                     <h5>4. YOUR UNDERTAKINGS</h5>
                    <p>4.1 You assume full responsibility for your and your Permitted Users’ use of the Service in accordance with this Agreement and with applicable local, state, federal, national and international laws, regulations and treaties, and warrant that you have obtained all rights in the Content to authorize Axios to input, process, distribute and display the Content as contemplated by the Agreement. </p>
                    <p><ul>4.2 You will not, and ensure that your Permitted Users will not, use the Service or Content for any use or purpose that: <li>(i) is obscene, libelous, blasphemous, defamatory, inciting hatred, terrorism or any similar offence; </li><li>(ii) infringes or misappropriates the intellectual property rights or violates the privacy rights of any third party (including without limitation, copyright, trademark, patent, trade secret, or other intellectual property right, moral right, or right of publicity); </li><li>(iii) is in violation or may encourage any manner of acting that would violate any applicable local, state, national and foreign laws, treatises and regulations; or </li><li>(iv) may drive or encourage any third party to do any of the above.</li></ul>
                        </p>
                    <p><ul>4.3 You will not, and will ensure that your Permitted Users will not: <li>(i) use the Service for non-business calls or abuse the Service; </li><li>(ii) resell, transfer, grant others permission to use the Service, pledge, lease, rent, or share your rights under this Agreement (including without limitation to any of your affiliates); </li><li>(iii) modify, remove or amend Axios’s name or logo, update, reproduce, duplicate, copy all or any part of the Service; </li><li>(iv) make any of the Service or Content available to anyone other than your employees and consultants for use for your benefit as intended pursuant to this Agreement, or use any Service or Content for the benefit of anyone other than you; </li><li>(v) use the Service in any way that restricts or inhibits the use of the Service; </li><li>(vi) access or attempt to access any of Axios’s systems, programs or data that are not made available for public use, or attempt to bypass any registration processes on the Service or any of the Service’s security and traffic management devices; or </li><li>(vii) attempt to decompile, disassemble, re-engineer or reverse engineer the Service or otherwise create or attempt to create or permit, allow, or assist others to extract source code of the Service, its structural framework or allow or facilitate a third party, to violate or infringe any rights of Axios’s or others or Axios policies or the operational or security mechanisms of the Service.</li>
                    </ul>
                    </p>
                    <p>
                    4.4 When using the Service in conjunction with other third party services, you will comply with the terms of service of such third party services. Axios shall not be liable for any termination, breach of terms or suspension of service resulting from your use of the Service.
                    </p>
                    <p>4.5 You may not access or use the Service if you are a direct competitor of Axios, or for monitoring the Service’s availability, performance or functionality, or for any other benchmarking or competitive purposes.</p>
                <h5>5. SUBSCRIPTION FEES </h5>

                    <p>5.1 In consideration for the right to use the Service under the terms herein, you will pay subscription fees in the amount and payment terms. You agree that in the event Axios is unable to collect the Subscription Fees owed to Axios for the Service, Axios may take any other steps it deems necessary to collect such fees from you and that you will be responsible for all costs and expenses incurred by Axios in connection with such collection activity, including collection fees, court costs and attorneys’ fees. You further agree that Axios may collect interest at the lesser of 1.0% per month or the highest amount permitted by law on any amounts not paid when due. Except to the extent otherwise expressly stated in this Agreement or in an order form, all obligations to pay Subscription Fees are non-cancelable and all payments are non-refundable. </p>
                    <p>5.2 Your Subscription Fees are exclusive of taxes, levies, duties or similar governmental assessments of any kind (excluding taxes based on Axios’s income, property and employees). You will be responsible for paying any and all such taxes.</p>

                    <p>5.3 Axios reserves the right to modify the Subscription Fees for the Service under one or more Order Forms, effective upon commencement of the next renewal subscription term of the relevant Order Form(s), by notifying you of such change in writing at least 30 days before the end of the then-current Subscription Term.</p>

                  <h5>6. PROPRIETARY RIGHTS; YOUR FEEDBACK </h5>
                    <p>6.1 All parts of the Service are protected by copyrights</p>
                    <p>6.2 Axios makes no claim of ownership as to your Content, the trademarks of any third party linked or displayed on the Service, or with respect to any publisher or publication mentioned on the Service. </p>
                    <p>6.3 In the course of using the Service, you or your Permitted Users may provide Axios with feedback and suggestions regarding the Service. You hereby assign to Axios ownership in all such feedback and suggestions and all rights therein, without any royalty or accounting obligations to you.</p>
                   <h5>7. WARRANTIES; DISCLAIMER </h5>
                    <p><ul>7.1 Each of you and Axios represent, warrant and covenant to the other that: <li>(a) it has the full corporate right, power and authority to enter into and perform this Agreement, and such execution and performance does not and will not violate any other agreement to which it is a party, and </li><li>(b) this Agreement constitutes its legal, valid and binding obligation.</li></ul>
                    </p>
                    <p>We may update or modify these Terms and Condition from time to time as the law or even our business requires. We will notify you in case of any changes to this Privacy Policy by placing a quick notice on our Sites. Please contact us if you have further queries.
                    </p>
                    <ul>
                    <p>You can find us at:</p>

                        <li>
                        Email: info@axiossoftworks.com
                        </li>
                        <li>
                    Official website: https://axiossoftworks.com/
                        </li>
                        <li>Contact number:
                    Office: +977 9801366352, +977 9801366353</li>
                    </ul>
                    
                   <ul>
                   <p>Kindly reach out to us if you’ve any queries.</p>
                <li><span className="bold">Email:</span> <a href="info@axiossoftworks.com">info@axiossoftworks.com</a> , <a href="info@nivid.app">info@nivid.app</a></li>
                <li><span className="bold">Official website:</span><a href="https://axiossoftworks.com/">https://axiossoftworks.com/</a> , <a href= "https://nivid.app/">https://nivid.app/</a></li>
    
                    <li><span className="bold">Contact number:</span> 9801366358, 9801366352</li>
                    <li><span className="bold">Location:</span> Ghokechaur, Banepa, kavre</li>
                       </ul> 
                

                </div>
            </div>
        </div>
        </div>
    )
}
export default TermOfUseComponent;