import React, { useState, useEffect } from "react";
import AdimssionFormHead from "../AdmissionForm/AdmissionFormHead/AdimssionFormHead";
import {
  FormikErrorMessage,
  useAdmissionFormFormikContext,
} from "../HorizontalStepper.js/FormikWrapper";
import "./ContactInfo.scss";
import {
  getProvince,
  getDistrict,
  getMunicipality,
  // getCurrProvince,
  // getCurrDistrict,
  // getCurrMunicipality,
} from "../Api/GetApi";

export const ContactInfo = ({
  schoolName,
  address,
  logo,
  email1,
  email2,
  contact1,
  contact2,
}) => {
  const { getFieldProps, setFieldValue, values } =
    useAdmissionFormFormikContext();

  const [province, setProvince] = useState([]);
  const [district, setDistrict] = useState([]);
  const [municipality, setMunicipality] = useState([]);
  const [currprovince, setCurrProvince] = useState([]);
  const [currdistrict, setCurrDistrict] = useState([]);
  const [currmunicipality, setCurrMunicipality] = useState([]);

  useEffect(() => {
    getProvince().then((res) => {
      setProvince(res?.data);
    });
  }, []);

  const calldistrict = (provinceid) => {
    getDistrict(provinceid).then((res) => {
      setDistrict(res?.data);
    });
  };

  const callcurrentdistrict = (currprovinceid) => {
    getDistrict(currprovinceid).then((res) => {
      setCurrDistrict(res?.data);
    });
  };

  const callmunicipality = (districid) => {
    getMunicipality(districid).then((res) => {
      setMunicipality(res?.data);
    });
  };
  const callcurrentmunicipality = (currdistricid) => {
    getMunicipality(currdistricid).then((res) => {
      setCurrMunicipality(res?.data);
    });
  };

  useEffect(() => {
    callcurrentdistrict(values.current_state);
  }, [values.current_state]);

  useEffect(() => {
    callcurrentmunicipality(values.current_district);
  }, [values.current_district]);

  useEffect(() => {
    calldistrict(values.state);
  }, [values.state]);

  useEffect(() => {
    callmunicipality(values.district);
  }, [values.district]);

  const Checkbox = (e) => {
    setFieldValue("isSameAsPermanent", e.target.checked);

    if (e.target.checked) {
      callcurrentdistrict(values.current_state);
      callcurrentmunicipality(values.current_district);

      setFieldValue("current_country", values.country);
      setFieldValue("current_state", values.state);
      setFieldValue("current_municipality", values.municipality);
      setFieldValue("current_street", values.street);
      setFieldValue("current_district", values.district);
      setFieldValue("current_ward_no", values.ward_no);
      setFieldValue("current_house_no", values.house_no);
    }
  };

  return (
    <div className="contact-info">
      <div className="section-gap">
        <div className="container">
          <div className="admission-form">
            <AdimssionFormHead
              schoolName={schoolName}
              address={address}
              logo={logo}
              email1={email1}
              email2={email2}
              contact1={contact1}
              contact2={contact2}
            />

            <div className="option-head">
              <div className="uppercase small-title center">
                Contact Address
              </div>
            </div>
            <div className="address-detail">
              <div className="uppercase medium-content blue-text">
                Permanent Address
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="field-input-block">
                    <p className="main-content name-tag">
                      Country:<span className="star">*</span>
                    </p>
                    <input
                      className="field-input"
                      value={values.country}
                      name="country"
                      disabled={true}
                      {...getFieldProps("country")}
                      onChange={(e) => {
                        setFieldValue("country", e.target.value);
                        if (values.isSameAsPermanent) {
                          setFieldValue("current_country", e.target.value);
                        }
                      }}
                    />
                    <FormikErrorMessage name="country" />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="field-input-block">
                    <p className="main-content name-tag">
                      State: <span className="star">*</span>
                    </p>
                    <select
                      className="field-input"
                      // {...getFieldProps("state")}
                      value={values.state}
                      onChange={(e) => {
                        // const selectedIndex = e.target.selectedIndex;
                        // const selectedOption = e.target.options[selectedIndex];
                        // const selectedSchoolName = selectedOption.text;
                        // const selectedProvinceId = selectedOption.value;
                        setFieldValue("state", e.target.value);
                        calldistrict(e.target.value);
                        if (values.isSameAsPermanent) {
                          setFieldValue("current_state", e.target.value);
                        }
                      }}
                    >
                      <option>--Select--</option>
                      {province?.map((item) => {
                        return (
                          <option key={item.id} name="state" value={item.id}>
                            {item.school_name}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <FormikErrorMessage name="state" />
                </div>
                <div className="col-md-4">
                  <div className="field-input-block">
                    <p className="main-content name-tag">District:<span className="star">*</span></p>
                    <select
                      className="field-input"
                      // {...getFieldProps("district")}
                      value={values.district}
                      onChange={(e) => {
                        setFieldValue("district", e.target.value);
                        callmunicipality(e.target.value);
                        if (values.isSameAsPermanent) {
                          setFieldValue("current_district", e.target.value);
                        }
                      }}
                    >
                      <option value="" disabled >--Select--</option>
                      {district?.map((item) => {
                        return (
                          <option key={item.id} name="district" value={item.id}>
                            {item.school_name}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <FormikErrorMessage name="district" />
                </div>
                <div className="col-md-4">
                  <div className="field-input-block">
                    <p className="main-content name-tag">Municipality:<span className="star">*</span></p>
                    <select
                      className="field-input"
                      {...getFieldProps("municipality")}
                      value={values.municipality}
                      onChange={(e) => {
                        // const selectedIndex = e.target.selectedIndex;
                        // const selectedOption = e.target.options[selectedIndex];
                        // const selectedSchoolName = selectedOption.text;
                        // const selectedMunicipalityId = e.target.value;

                        setFieldValue("municipality", e.target.value);

                        if (values.isSameAsPermanent) {
                          // setCurrMunicipalityId(selectedMunicipalityId);
                          setFieldValue("current_municipality", e.target.value);
                        }
                      }}
                    >
                      <option value="" disabled>--Select--</option>
                      {municipality?.map((item) => {
                        return (
                          <option key={item.id} name="municipality">
                            {item.school_name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="field-input-block">
                    <p className="main-content name-tag">Ward No:<span className="star">*</span></p>
                    <input
                      className="field-input"
                      type="number"
                      name="ward_no"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      {...getFieldProps("ward_no")}
                      onChange={(e) => {
                        setFieldValue("ward_no", e.target.value);
                        if (values.isSameAsPermanent) {
                          setFieldValue("current_ward_no", e.target.value);
                        }
                      }}
                    />
                  </div>
                  <FormikErrorMessage name="ward_no" />
                </div>

                <div className="col-md-4">
                  <div className="field-input-block">
                    <p className="main-content name-tag">Street:</p>
                    <input
                      className="field-input"
                      name="street"
                      {...getFieldProps("street")}
                      onChange={(e) => {
                        setFieldValue("street", e.target.value);
                        if (values.isSameAsPermanent) {
                          setFieldValue("current_street", e.target.value);
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="field-input-block">
                    <p className="main-content name-tag">House No.:</p>
                    <input
                      className="field-input"
                      type="number"
                      name="house_no"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      {...getFieldProps("house_no")}
                      onChange={(e) => {
                        setFieldValue("house_no", e.target.value);
                        if (values.isSameAsPermanent) {
                          setFieldValue("current_house_no", e.target.value);
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="address-detail">
              <div className="row">
                <div className="col-md-6">
                  <div className="uppercase medium-content blue-text">
                    Current Contact Address
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="checkbox-block">
                    <div className="radio-label d-flex">
                      <input
                        type="checkbox"
                        name="same"
                        checked={values.isSameAsPermanent}
                        onClick={(e) => {
                          Checkbox(e);
                        }}
                      />
                      <label className="main-content">
                        Is same as Permanent Address
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="field-input-block">
                    <p className="main-content name-tag">Country:<span className="star">*</span></p>
                    <input
                      className="field-input"
                      value={values.current_country}
                      name="current_country"
                      disabled={true}
                      {...getFieldProps("current_country")}
                      setFieldValue={(e) => {
                        setFieldValue("current_country", e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="field-input-block">
                    <p className="main-content name-tag">
                      State: <span className="star">*</span>
                    </p>
                    <select
                      className="field-input"
                      // {...getFieldProps("current_state")}
                      value={values.current_state}
                      onChange={(e) => {
                        setFieldValue("current_state", e.target.value);
                        callcurrentdistrict(e.target.value);
                      }}
                    >
                      <option>--Select--</option>
                      {province?.map((item) => {
                        return (
                          <option
                            key={item.id}
                            name="current_state"
                            value={item.id}
                          >
                            {item.school_name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="field-input-block">
                    <p className="main-content name-tag">District:<span className="star">*</span></p>
                    <select
                      className="field-input"
                      // {...getFieldProps("current_district")}
                      value={values.current_district}
                      onChange={(e) => {
                        setFieldValue("current_district", e.target.value);
                        callcurrentmunicipality(e.target.value);
                      }}
                    >
                      <option>--Select--</option>
                      {currdistrict?.map((item) => {
                        return (
                          <option
                            key={item.id}
                            name="current_district"
                            value={item.id}
                          >
                            {item.school_name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="field-input-block">
                    <p className="main-content name-tag">Municipality:</p>
                    <select
                      className="field-input"
                      {...getFieldProps("current_municipality")}
                      value={values.current_municipality}
                      onChange={(e) => {
                        const selectedMunicipality = e.target.value;
                        setFieldValue(
                          "current_municipality",
                          selectedMunicipality
                        );
                      }}
                    >
                      <option>--Select--</option>
                      {currmunicipality?.map((item) => {
                        return (
                          <option key={item.id} name="current_municipality">
                            {item.school_name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="field-input-block">
                    <p className="main-content name-tag">
                      Ward No:<span className="star">*</span>
                    </p>
                    <input
                      className="field-input"
                      type="number"
                      name="current_ward_no"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      {...getFieldProps("current_ward_no")}
                      setFieldValue={(e) => {
                        setFieldValue("current_ward_no", e.target.value);
                      }}
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="field-input-block">
                    <p className="main-content name-tag">Street:</p>
                    <input
                      className="field-input"
                      name="current_street"
                      {...getFieldProps("current_street")}
                      setFieldValue={(e) => {
                        setFieldValue("current_street", e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="field-input-block">
                    <p className="main-content name-tag">House No.:</p>
                    <input
                      className="field-input"
                      type="number"
                      name="current_house_no"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      {...getFieldProps("current_house_no")}
                      setFieldValue={(e) => {
                        setFieldValue("current_house_no", e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
