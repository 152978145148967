import { useStepper, withStepper } from "react-usestepper";
import "./HorizontalStepper.scss";

const Stepper = ({
  config = [],
  onStepsComplete = () => {},
  onBack = () => {},
  onStepsReset = () => {},
  onStepChange = () => {},
  navlist,
  Footer,

}) => {
  const { body, currentStep } = useStepper({
    totalNumberOfSteps: config.length,
    onStepsComplete,
    onBack,
    onStepChange,
    onStepsReset,
    bodyComponents: config?.map((item) => item.body),
  });
  
  return (
    <>
    
    <div className="stepper-block">
      <div className="text-block center">
        {config?.map((item, index) => {
          return (
            <label
              key={index}
              className={`stepper-head d-flex ${
                currentStep === index + 1 ? "active-head" : ""
              }`}
            >
              <input
                type="radio"
                name="radio"
                value="billing"
                className={`input-color ${
                  currentStep === index + 1
                    ? "active-shadow"
                    : currentStep > index + 1
                    ? "active-button"
                    : "input-color"
                }`}
              />
              <div
                className={`d-column main-content title-wrapper ${
                  currentStep === index + 1 ? "stepper-text" : ""
                }`}
              >
                <span className="item-icon center">{item.icon}</span>
                {item.title}
              </div>
              <div
                className={` ${
                  currentStep > index + 1
                    ? "stepper-horizontal-line-green"
                    : "stepper-horizontal-line-gray"
                }`}
              ></div>
            </label>
          );
        })}
      </div>
      {body}
      {Footer}
    </div>
    </>
  );
};

export const HorizontalStepper = withStepper(
  //@ts-ignore
  Stepper
);
