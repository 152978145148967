import React from "react";
import "./thankyou.scss";
import thankyou from "../../images/undraw_mail_sent_re_0ofv.svg";

export default function Thankyou() {
  return (
    <div className="thankyou-page">
      <div className="thankyou-page-banner" />
      <div className="thankyou-section">
        <img src={thankyou} />
        <h1>Thank you</h1>
        <p>your inquiry has been submitted successfully</p>
        <p2>If you have any questions, please feel free to contact us</p2>
        <button
          className="main-btn"
          onClick={() => {
            window?.open("/", "_self");
          }}
        >
          Home page
        </button>
      </div>
    </div>
  );
}
