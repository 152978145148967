import React from "react";
import "./FAQs.scss";
import Accordian from "../Accordian/Accordian";
import MetaConfigure from "../helmet/helmet";

function FAQs(props) {
  return (
    <div className="faq-section section-top">
      {props.type === "show" ? (
        <>
          <h1 className="main-title" data-aos-once={true} data-aos="fade-down">
            Answer to the questions we get asked about NIVID
          </h1>{" "}
          <MetaConfigure title="FAQ-Nivid" />
          <div
            className="content-style"
            data-aos-once={true}
            data-aos="fade-left"
          ></div>
        </>
      ) : (
        <>
          <h1 className="main-title" data-aos-once={true} data-aos="fade-down">
            Do you have any questions???
          </h1>
          <div
            className="content-style"
            data-aos-once={true}
            data-aos="fade-left"
          ></div>
          <p className="content" data-aos-once={true} data-aos="fade-right">
            Couldn’t find what you are looking for? Check our <span>FAQ.</span>
          </p>
        </>
      )}

      <Accordian isHomePage={props.isHomePage} />
    </div>
  );
}

export default FAQs;
