import React, { useState } from "react";
import AdimssionFormHead from "../AdmissionForm/AdmissionFormHead/AdimssionFormHead";
import {
  FormikErrorMessage,
  useAdmissionFormFormikContext,
} from "../HorizontalStepper.js/FormikWrapper";
import "./OtherInfo.scss";

export const OtherInfo = ({ schoolName, address, logo, email, contact, subDomain }) => {
  const { setFieldValue, getFieldProps, values } =
    useAdmissionFormFormikContext();
  const [isAnyOtherChecked, setIsAnyOtherChecked] = useState(false);

  return (
    <div className="other-info">
      <div className="section-gap">
        <div className="container">
          <div className="admission-form">
            <AdimssionFormHead
              schoolName={schoolName}
              address={address}
              logo={logo}
              email={email}
              contact={contact}
            />

            <div className="option-head">
              <div className="uppercase small-title center">
                Other Information
              </div>
            </div>
            <div className="otherinfo-detail">
              <div className="uppercase medium-content blue-text"></div>
              <div className="row">
                <div className="col-md-5">
                  <div className="field-input-block">
                    <p className="main-content name-tag">
                      Do you require transportation?
                      <span className="star">*</span>
                    </p>
                  </div>
                </div>

                <div className="col-md-7">
                  <div className="other-input">
                    {["Yes", "No"]?.map((item, index) => {
                      return (
                        <div className="input-label">
                          <input
                            key={index}
                            type="radio"
                            name="transport"
                            checked={item === values.transport}
                            onChange={(e) => {
                              setFieldValue("transport", item);
                            }}
                          />
                          <label>{item}</label>
                        </div>
                      );
                    })}
                    {/* <div className="input-label">
                          <input
                            type="radio"
                            id="1"
                            name="transport"
                            value="yes"
                            onChange={(e) => {
                              setTransport(e.target.value)
                            }}
                            // {...getFieldProps("transport")}
                          />
                          <label htmlFor="yes">yes</label>


                          <input
                            type="radio"
                            id="1"
                            name="transport"
                            value="no"
                            onChange={(e) => {
                              setTransport(e.target.value)
                            }}
                            // {...getFieldProps("transport")}
                          />
                          <label htmlFor="no">no</label>
                        </div> */}
                  </div>
                </div>

                <div className="col-md-5">
                  <div className="field-input-block">
                    <p className="main-content name-tag">
                      Do you require canteen facilities?
                      <span className="star">*</span>
                    </p>
                  </div>
                </div>

                <div className="col-md-7">
                  <div className="other-input">
                    {["Yes", "No"]?.map((item, index) => {
                      return (
                        <div className="input-label">
                          <input
                            type="radio"
                            id={index}
                            name="canteen"
                            checked={item === values.canteen}
                            onChange={(e) => {
                              setFieldValue("canteen", item);
                            }}
                          />
                          <label htmlFor="yes">{item}</label>
                        </div>
                      );
                    })}
                  </div>
                  <FormikErrorMessage name="canteen" />
                </div>
                <div className="col-md-5">
                  <div className="field-input-block">
                    <p className="main-content name-tag">
                      Do you require Hostel facilities?
                      <span className="star">*</span>
                    </p>
                  </div>
                </div>
                <div className="col-md-7">
                  <div className="other-input">
                    {["Yes", "No"]?.map((item, index) => {
                      return (
                        <div className="input-label">
                          <input
                            type="radio"
                            id={index}
                            name="hostel"
                            checked={item === values.hostel}
                            onChange={(e) => {
                              setFieldValue("hostel", item);
                            }}
                          />
                          <label htmlFor={item}>{item}</label>
                        </div>
                      );
                    })}
                  </div>
                  <FormikErrorMessage name="hostel" />
                </div>
                <div className="col-md-5">
                  <div className="field-input-block">
                    <p className="main-content name-tag">
                      Do you require good sporting facility?
                      <span className="star">*</span>
                    </p>
                  </div>
                </div>
                <div className="col-md-7">
                  <div className="other-input">
                    {["Yes", "No"]?.map((item, index) => {
                      return (
                        <div className="input-label">
                          <input
                            type="radio"
                            id={index}
                            name="good_sporting_facility"
                            checked={item.toLowerCase() === "yes" ? values.good_sporting_facility: !values.good_sporting_facility}
                            onChange={(e) => {
                              if(item.toLowerCase() === "yes") {
                              setFieldValue("good_sporting_facility", true);}else {
                                setFieldValue("good_sporting_facility", false)
                              }
                            }}
                          />
                          <label htmlFor={item}>{item}</label>
                        </div>
                      );
                    })}
                  </div>
                  <FormikErrorMessage name="good_sporting_facility" />
                </div>
                <div className="col-md-5">
                  <div className="field-input-block">
                    <p className="main-content name-tag">
                      Do you require best faculty?
                      <span className="star">*</span>
                    </p>
                  </div>
                </div>
                <div className="col-md-7">
                  <div className="other-input">
                    {["Yes", "No"]?.map((item, index) => {
                      return (
                        <div className="input-label">
                          <input
                            type="radio"
                            id={index}
                            name="best_faculty"
                            checked={item.toLowerCase() === "yes" ? values.best_faculty: !values.best_faculty}
                            onChange={(e) => {
                              if(item.toLowerCase() === "yes") {
                              setFieldValue("best_faculty", true);}else {
                                setFieldValue("best_faculty", false)
                              }
                            }}
                          />
                          <label htmlFor={item}>{item}</label>
                        </div>
                      );
                    })}
                  </div>
                  <FormikErrorMessage name="best_faculty" />
                </div>
                <div className="col-md-5">
                  <div className="field-input-block">
                    <p className="main-content name-tag">
                      Choose Stream:
                      {subDomain === "urbana" && 
                      <span className="star">*</span>
                    }
                    </p>
                  </div>
                </div>

                <div className="col-md-7">
                  <div className="other-input">
                    {["Computer", "Biology"]?.map((item, index) => {
                      return (
                        <div className="input-label">
                          <input
                            type="radio"
                            id={index}
                            name="stream"
                            checked={item === values.stream}
                            onChange={(e) => {
                              setFieldValue("stream", item);
                            }}
                          />
                          <label htmlFor={index}>{item}</label>
                        </div>
                      );
                    })}
                  </div>
                  <FormikErrorMessage name="stream" />
                </div>
                {/* change */}
                <div className="col-md-5">
                  <div className="field-input-block">
                    <p className="main-content name-tag">
                      Previous School Type:
                      <span className="star">*</span>
                    </p>
                  </div>
                </div>

                <div className="col-md-7">
                  <div className="other-input">
                    {["Government", "Private"]?.map((item, index) => {
                      return (
                        <div className="input-label">
                          <input
                            type="radio"
                            id={index}
                            name="previous_school_type"
                            checked={item === values.previous_school_type}
                            onChange={(e) => {
                              setFieldValue("previous_school_type", item);
                            }}
                          />
                          <label htmlFor={index}>{item}</label>
                        </div>
                      );
                    })}
                  </div>
                  <FormikErrorMessage name="previous_school_type" />
                </div>
                <div className="col-md-5">
                  <div className="field-input-block">
                    <p className="main-content name-tag">
                      Previous School Name:
                      <span className="star">*</span>
                    </p>
                    <input type="text" className="field-input" name="previous_school" onChange={(e) => {
                  setFieldValue("previous_school", e.target.value);
                }} />
                  </div>
                  <FormikErrorMessage name="previous_school" />
                </div>
               
                <div className="col-md-5">
                  <div className="field-input-block">
                    <p className="main-content name-tag">
                      Previous GPA:
                      <span className="star">*</span>
                    </p>
                    <input type="text" className="field-input" name="previous_gpa" onChange={(e) => {
                  setFieldValue("previous_gpa", e.target.value);
                }} />
                  </div>
                  <FormikErrorMessage name="previous_gpa" />
                </div>
               

                <div className="col-md-12">
                  <div className="field-input-block">
                    <p className="main-content name-tag">
                      What do you want to become?
                    </p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="field-input-block">
                    <p className="main-content name-tag">First Choice:</p>
                    <input
                      type="text"
                      className="field-input"
                      name="want_to_become_first"
                      onChange={(e) => {
                        setFieldValue("want_to_become_first", e.target.value);
                      }}
                      {...getFieldProps("want_to_become_first")}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="field-input-block">
                    <p className="main-content name-tag">Second Choice:</p>
                    <input
                      type="text"
                      className="field-input"
                      name="want_to_become_second"
                      onChange={(e) => {
                        setFieldValue("want_to_become_second", e.target.value);
                      }}
                      {...getFieldProps("want_to_become_second")}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="field-input-block">
                    <div className="d-flex align-items-center">
                      <input type="checkbox" name="not_decided" id="not_decided" />
                      <label className="main-content name-tag ml-2" htmlFor="not_decided">Not Decided</label>
                    </div>
                  </div>
                </div>

                <div className="col-md-5">
                  <div className="field-input-block">
                    <p className="main-content name-tag">
                      Where do you want to pursue bachelor level of study (after +2)
                    </p>
                  </div>
                </div>
                <div className="col-md-7">
                  <div className="other-input">
                    {["Nepal", "USA", "Australia"]?.map((item, index) => {
                      return (
                        <div className="input-label">
                          <input
                            type="radio"
                            id={index}
                            name="pursue_after_plus2"
                            checked={item === values.pursue_after_plus2}
                            onChange={(e) => {
                              setFieldValue("pursue_after_plus2", item);
                              setIsAnyOtherChecked(false);
                            }}
                          />
                          <label htmlFor={item}>{item}</label>
                        </div>
                      );
                    })}
                    <div className="other-input">
                      <div className="input-label d-flex">
                      <input
                        type="radio"
                        id={"any_other"}
                        name="pursue_after_plus2"
                        checked={isAnyOtherChecked}
                        onChange={(e) => {
                          setFieldValue("pursue_after_plus2", "");
                          setIsAnyOtherChecked(e.target.checked);
                        }}
                      />
                      <label className="main-content name-tag ml-2 mb-0" htmlFor="not_decided">Any Other</label>
                       {isAnyOtherChecked &&
                      <input
                          type="text"
                          className="field-input"
                          name="pursue_after_plus2"
                          onChange={(e) => {
                            setFieldValue("pursue_after_plus2", e.target.value);
                          }}
                          {...getFieldProps("pursue_after_plus2")}
                        />}
                        </div>
                    </div>
                  </div>
                  <FormikErrorMessage name="country" />
                </div>

                <div className="col-md-12">
                  <p className="main-content name-tag">
                    Referees by (if any):
                  </p>
                </div>
                <div className="col-md-4">
                  <div className="field-input-block">


                    <p className="main-content name-tag">Name:</p>
                    <input
                      type="name"
                      className="field-input"
                      name="referral_name"
                      onChange={(e) => {
                        setFieldValue("referral_name", e.target.value);
                      }}
                      {...getFieldProps("referral_name")}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="field-input-block">
                    <p className="main-content name-tag">Relation:</p>
                    <input
                      type="text"
                      name="reffereal_relation"
                      className="field-input"
                      onChange={(e) => {
                        setFieldValue("reffereal_relation", e.target.value);
                      }}
                      {...getFieldProps("reffereal_relation")}
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="field-input-block">
                    <p className="main-content name-tag">Phone Number:</p>
                    <input
                      type="number"
                      name="refferal_contact"
                      className="field-input"
                      onChange={(e) => {
                        setFieldValue("refferal_contact", e.target.value);
                      }}
                      {...getFieldProps("refferal_contact")}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="field-input-block">
                    <p className="main-content name-tag">Email:</p>
                    <input
                      type="email"
                      name="refferal_email"
                      className="field-input"
                      onChange={(e) => {
                        setFieldValue("refferal_email", e.target.value);
                      }}
                      {...getFieldProps("refferal_email")}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
