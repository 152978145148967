import React from "react";
import "./History.scss";

function History() {
  return (
    <div className="history-section">
      <div class="container">
        <h1 className="main-title">History of NIVID</h1>
        <div className="content-style"></div>
        <div className="timeline-section">
          <div class="page">
            <div class="row">
              <ul id="timeline" class="timeline">
                <div class="arrowhead"></div>
                <li class="timeline-vert ">
                  <div class="timeline-badge"> </div>
                  <div class="timeline-panel">
                    <div class="timeline-heading bg-blue">
                      <div class="timeline-title text-init">
                        <h1 className="title-content">October 2018</h1>
                        <p className="content">
                          Initiation of development of the products.
                        </p>
                      </div>
                    </div>

                    <div class="timeline-body"></div>
                  </div>
                </li>

                <li class="timeline-inverted ">
                  <div class="timeline-badge"> </div>
                  <div class="timeline-panel">
                    <div class="timeline-heading bg-pink">
                      <div class="timeline-title text-init">
                        <h1 className="title-content">January 2019</h1>
                        <p className="content">
                          Pilot project in two schools of banepa.
                        </p>
                      </div>
                    </div>

                    <div class="timeline-body"></div>
                  </div>
                </li>
                <li class="timeline-vert ">
                  <div class="timeline-badge"> </div>
                  <div class="timeline-panel">
                    <div class="timeline-heading bg-blue">
                      <div class="timeline-title text-init">
                        <h1 className="title-content">April 2019</h1>
                        <p className="content">
                          Launching of the product in six school of Kavre
                          district (a total of 6,500 students.)
                        </p>
                      </div>
                    </div>

                    <div class="timeline-body"></div>
                  </div>
                </li>
                <li class="timeline-inverted ">
                  <div class="timeline-badge"> </div>
                  <div class="timeline-panel">
                    <div class="timeline-heading bg-pink">
                      <div class="timeline-title text-init">
                        <h1 className="title-content">December 2020</h1>
                        <p className="content">
                          Implemented in six more schools in Kavre and Bhaktapur
                          district.
                        </p>
                      </div>
                    </div>

                    <div class="timeline-body"></div>
                  </div>
                </li>

                <li class="timeline-vert ">
                  <div class="timeline-badge"> </div>
                  <div class="timeline-panel">
                    <div class="timeline-heading bg-blue">
                      <div class="timeline-title text-init">
                        <h1 className="title-content">
                          2020 (During COVID 19 Period)
                        </h1>
                        <p className="content">
                          Implemented by more than a dozen schools for
                          assistance in online academic activities.
                        </p>
                      </div>
                    </div>

                    <div class="timeline-body"></div>
                  </div>
                </li>
                <li class="timeline-inverted ">
                  <div class="timeline-badge"> </div>
                  <div class="timeline-panel">
                    <div class="timeline-heading bg-pink">
                      <div class="timeline-title text-init">
                        <h1 className="title-content">Post Lockdown Period</h1>
                        <p className="content">
                          Provision of service to 17 more schools free of cost,
                          considering the financial burden faced by schools
                          during the lockdown period.
                        </p>
                      </div>
                    </div>

                    <div class="timeline-body"></div>
                  </div>
                </li>
                <li class="timeline-vert ">
                  <div class="timeline-badge"> </div>
                  <div class="timeline-panel">
                    <div class="timeline-heading bg-blue">
                      <div class="timeline-title text-init">
                        <h1 className="title-content">Present</h1>
                        <p className="content">
                          Working towards betterment of education system of
                          Nepal,
                        </p>
                      </div>
                    </div>

                    <div class="timeline-body"></div>
                  </div>
                </li>

                <div></div>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default History;
