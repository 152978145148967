import React, { useState } from "react";
import Banner from "../Banner/Banner";
import "./DemoPage.scss";
import * as Yup from "yup";
import { logAnalyticEvent, snakeCase } from "../../firebaseconfig";
import { toast, ToastContainer } from "react-toastify";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Demorequest } from "../Api/PostApi";
import Demo from "../Demo/Demo";
import MetaConfigure from "../helmet/helmet";

function DemoPage() {
  const [buttonDisable, setButtonDisable] = useState(false);
  const [preLoader, setPreLoader] = useState(false);
  const successNotify = () => {
    toast.success("Form Submitted Sucessfully");
  };

  const errorNotify = () => {
    toast.error("Something Wrong!!");
  };
  window.scrollTo(0, 0);
  return (
    <>
      <div className="demopage-section">
        <MetaConfigure title="Demo-Nivid" />

        <ToastContainer />

        <div className="banner-half">
          <Banner
            type="show"
            title="Demo Page"
            subTitle="NIVID School Management System"
          />
        </div>
        <div className="container">
          <h2 className="main-title">Demo Request Form</h2>
          <p className="content mg-70">
            Seeing is believing, as they say. Schedule a live demo with one of
            our product specialists at your leisure, from 10 a.m. to 5 p.m. GMT,
            Sunday through Friday. We are here to assist you and your school.
          </p>

          <div className="card-section">
            <p className="content text-init">
              Please fill-up the form and our team will contact you within 24
              hours.
            </p>
            <Formik
              initialValues={{
                name: "",
                phone: "",
                email: "",
                designation: "",
                schoolname: "",
                schoolemail: "",
                schooladdress: "",
                schoolphone: "",
                no_students: "",
                message: "",
              }}
              validationSchema={Yup.object({
                name: Yup.string().required("Full name is required"),
                phone: Yup.string()
                  .min(10, "Phone number is too short!")
                  .max(10, "Phone number is too long!")
                  .required("Phone number is Required"),
                email: Yup.string().email("Invalid email"),
                // designation: Yup.string().required("Designation is required"),
                schoolname: Yup.string().required(
                  "School name must be required"
                ),

                schoolemail: Yup.string().required(
                  "School email name must be required"
                ),
                no_students: Yup.string().required(
                  "No. of student is required"
                ),
                schoolphone: Yup.string()
                  .min(10, "Phone number is too short!")
                  .max(10, "Phone number is too long!")
                  .required("School phone name must be required"),

                schooladdress: Yup.string().required(
                  "School address must be required"
                ),

                message: Yup.string().required("Message must be required"),
              })}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                setPreLoader(true);
                const postData = new FormData();
                postData.append("name", values.name);
                postData.append("phone", values.phone);
                postData.append("email", values.email);
                postData.append("school_phone", values.schoolphone);
                postData.append("no_student", values.no_students);
                postData.append("school_email", values.schoolemail);
                postData.append("designation", values.designation);
                postData.append("school_name", values.schoolname);
                postData.append("school_address", values.schooladdress);
                postData.append("message", values.message);
                setPreLoader(true);

                Demorequest(postData).then((res) => {
                  if (res.status === 200) {
                    successNotify();
                    setPreLoader(false);
                    setTimeout(() => {
                      setButtonDisable(false);
                    }, 4000);
                    resetForm();
                  } else {
                    errorNotify();
                  }

                  setSubmitting(false);
                });
              }}
            >
              <Form>
                <div className="row">
                  <div className="col-md-6 ">
                    <div className="input-field">
                      <p> Name</p>
                      <Field
                        className="form-control"
                        name="name"
                        type="text"
                        onKeyUp={(e) => {
                          const nameRegex = /^[A-Za-z\s]+$/;

                          if (!nameRegex.test(e.target.value)) {
                            toast.error(
                              "Invalid name format. Only letters and spaces are allowed."
                            );
                          }
                        }}
                      />
                      <span className="error">
                        <ErrorMessage name="name" />
                      </span>
                    </div>
                  </div>
                  <div className="col-md-6 ">
                    <div className="input-field">
                      <p>Email</p>
                      <Field
                        className="form-control"
                        name="email"
                        type="email"
                      />
                      <span className="error">
                        <ErrorMessage name="email" />
                      </span>
                    </div>
                  </div>

                  <div className="col-md-6 ">
                    <div className="input-field">
                      <p>Phone</p>
                      <Field
                        className="form-control"
                        name="phone"
                        type="text"
                        max="10"
                        pattern="^[0-9]*$"
                      />
                      <span class="error">
                        <ErrorMessage name="phone" />
                      </span>
                    </div>
                  </div>

                  <div className="col-md-6 ">
                    <div className="input-field">
                      <p>Designation</p>
                      <Field
                        className="form-control"
                        name="designation"
                        type="text"
                      />
                      <span class="error">
                        <ErrorMessage name="designation" />
                      </span>
                    </div>
                  </div>

                  <div className="col-md-6 ">
                    <div className="input-field">
                      <p>School Name</p>
                      <Field
                        className="form-control"
                        name="schoolname"
                        type="text"
                      />
                      <span class="error">
                        <ErrorMessage name="schoolname" />
                      </span>
                    </div>
                  </div>
                  <div className="col-md-6 ">
                    <div className="input-field">
                      <p>School Email</p>
                      <Field
                        className="form-control"
                        name="schoolemail"
                        type="email"
                      />
                      <span class="error">
                        <ErrorMessage name="schoolemail" />
                      </span>
                    </div>
                  </div>

                  <div className="col-md-6 ">
                    <div className="input-field">
                      <p>School Phone</p>
                      <Field
                        className="form-control"
                        name="schoolphone"
                        type="text"
                        max="10"
                        pattern="^[0-9]*$"
                      />
                      <span class="error">
                        <ErrorMessage name="schoolphone" />
                      </span>
                    </div>
                  </div>

                  <div className="col-md-6 ">
                    <div className="input-field">
                      <p>School Address</p>
                      <Field
                        className="form-control"
                        name="schooladdress"
                        type="text"
                      />
                      <span class="error">
                        <ErrorMessage name="schooladdress" />
                      </span>
                    </div>
                  </div>
                  <div className="col-md-6 ">
                    <div className="input-field">
                      <p>No. of Students</p>
                      <Field
                        className="form-control"
                        name="no_students"
                        type="number"
                      />
                      <span class="error">
                        <ErrorMessage name="no_students" />
                      </span>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <p>
                      Are you using any school SMS(School Management System)
                      already ?
                    </p>
                    <div>
                      <input
                        id="radio-1"
                        class="radio-custom mr-1"
                        name="radio-group"
                        type="radio"
                        checked
                      />
                      <label
                        for="radio-1"
                        class="radio-custom-label label-text  mr-2"
                      >
                        Yes
                      </label>
                      <input
                        id="radio-1"
                        class="radio-custom mr-1"
                        name="radio-group"
                        type="radio"
                        checked
                      />
                      <label
                        for="radio-1"
                        class="radio-custom-label label-text mr-2"
                      >
                        No
                      </label>
                    </div>
                  </div>

                  <div className="col-md-12 ">
                    <div className="input-field">
                      <p>Message</p>
                      <Field
                        as="textarea"
                        className="form-control"
                        name="message"
                        type="text"
                      />
                      <span class="error">
                        <ErrorMessage name="message" />
                      </span>
                    </div>
                  </div>
                </div>

                <button
                  disabled={buttonDisable}
                  type="submit"
                  onClick={() => logAnalyticEvent(snakeCase("Send_Message"))}
                  className="main-btn text-init  "
                >
                  Send a Request
                  {preLoader ? (
                    <img
                      className="spin-size"
                      src={require("../../images/feature-img/spin.gif").default}
                      alt="img"
                    />
                  ) : (
                    ""
                  )}
                </button>
              </Form>
            </Formik>
          </div>
        </div>
        <Demo
          title="Contact Us Directly"
          btn_text="Contact Us"
          btn_url="/contactus"
        />
      </div>
    </>
  );
}

export default DemoPage;
