import React from "react";
import "./WebService.scss";
import { logAnalyticEvent, snakeCase } from "../../firebaseconfig";
import { Link } from "react-router-dom";

function WebService() {
  return (
    <div className="webservice-section section-top">
      <div className="web-bg">
        {/* <img
          src={require("../../images/webservice-img/pattern.svg").default}
          alt="img"
          data-aos-once={true}
          data-aos="fade-down"
        /> */}

        <div className="webservice-content">
          <h2 className="main-title" data-aos-once={true} data-aos="fade-down">
            Control everything in Single Page
          </h2>
          <div
            className="content-style"
            data-aos-once={true}
            data-aos="fade-left"
          ></div>
          <p className="content" data-aos-once={true} data-aos="fade-right">
            Monitor each student's attendance, assignment, and accounting
            reports, as well as their daily activities at school.
          </p>
          <Link to="products">
            <button
              className="main-btn"
              onClick={() =>
                logAnalyticEvent(snakeCase("Webservice") + "_clicked")
              }
            >
              Learn More
            </button>
          </Link>
        </div>

        <div className="web-img">
          <img
            src={require("../../images/web.png").default}
            alt="img"
            data-aos-once={true}
            data-aos="fade-up"
          />
        </div>
      </div>
    </div>
  );
}

export default WebService;
