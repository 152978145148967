import { ErrorMessage, Formik, useFormikContext } from "formik";
import html2pdf from "html2pdf.js";
import * as React from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { handlePdfDownload } from "../../utils/services/downloadService";
import { PostAdmission } from "../Api/PostApi";
import { useToast } from "../Context/toast";
import PreviewDetails from "../Previewdetails/PreviewDetails";
import { useHistory } from "react-router-dom";
import { post } from "jquery";

export const initialValues = {
  student_name: "",
  middleName: "",
  lastName: "",
  grade_token: "",
  gender: "",
  dob: "",
  nationality: "",
  email: "",
  phone: "",
  mobile: "",
  religion: "",
  ethnicity: "",
  blood_group: "",
  // province: "",
  municipality: "",
  district: "",
  father_name: "",
  mother_name: "",
  father_email: "",
  occupation: "",
  father_number: "",
  father_office: "",
  father_office_number: "",
  mother_email: "",
  mother_occupation: "",
  mother_number: "",
  mother_post: "",
  mother_office: "",
  mother_office_number: "",
  guardian_name: "",
  guardian_number: "",
  realtion: "",
  guardian_occupation: "",
  guardian_phone: "",
  country: "Nepal",
  current_country: "Nepal",
  current_state: "",
  current_municipality: "",
  current_district: "",
  current_ward_no: "",
  current_house_no: "",
  ward_no: "",
  street: "",
  house_no: "",
  transport: "Yes",
  hostel: "",
  canteen: "",
  referral_name: "",
  refferal_contact: "",
  reffereal_relation: "",
  refferal_email: "",
  isSameAsPermanent: false,
  images: {
    student_image: "",
    student_signature: "",
    pdfs: [{ fileType: "", file: [] }],
  },
  subdomain: "", 
  previous_school: "", 
  previous_school_type: "", 
  previous_gpa:"", 
  want_to_become_first: "", 
  want_to_become_second: "", 
  pursue_after_plus2: "",
  good_sporting_facility: false, 
  best_faculty: false
};

const history = useHistory;

const validationSchema = Yup.object().shape({
  student_name: Yup.string().required("Please enter your name"),
  grade_token: Yup.string().required("Please select your grade"),
  gender: Yup.string().required("Please select your gender"),
  dob: Yup.string().required("Please select your date of birth"),
  nationality: Yup.string().required("Please select your nationality"),
  email: Yup.string().required("Please enter your email address"),
  mobile: Yup.string().required("Please enter your mobile number"),
  // province: Yup.string().required("Please select your province number"),
  ward_no: Yup.string().required("Please enter your Ward Number"),
  state: Yup.string().required("Please select your state"),
  transport: Yup.string().required(
    "Please select if transportation facilities is required or not"
  ),
  canteen: Yup.string().required(
    "Please select if canteen facilities is required or not"
  ),
  hostel: Yup.string().required(
    "Please select if hostel facilities is required or not"
  ),
  previous_school: Yup.string().required(
    "Please provide previous school"
  ),
  previous_school_type: Yup.string().required(
    "Please provide previous school type"
  ),
  previous_gpa: Yup.string().required(
    "Please provide previous GPA"
  ),
  good_sporting_facility: Yup.boolean().required(
    "Please select one option"
  ),
  best_faculty: Yup.boolean().required(
    "Please select one option"
  ),
});

export const useAdmissionFormFormikContext = () => {
  const formik = useFormikContext();
  if (!formik) {
    throw new Error("Formik context must be used within a Formik");
  }
  return formik;
};
export const FormikWrapper = ({ children }) => {
  const { showErrorMessage } = useToast();
  const history = useHistory();
  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    const data = {
      ...values,
    };

    const postData = new FormData();
    const fullName =   values.student_name + " " + values.middleName + " " + values.lastName
    postData.append("student_name", fullName);
    // postData.append("middleName", values.middleName);
    // postData.append("lastname", values.lastName);
    postData.append("grade_token", values.grade_token);
    postData.append("gender", values.gender);
    postData.append("dob", values.dob);
    postData.append("nationality", values.nationality);
    postData.append("email", values.email);
    postData.append("phone", values.phone);
    postData.append("mobile", values.mobile);
    postData.append("religion", values.religion);
    postData.append("ethnicity", values.ethnicity);
    postData.append("blood_group", values.blood_group);
    // postData.append("province", values.province);
    postData.append("municipality", values.municipality);
    postData.append("district", values.district);
    postData.append("father_name", values.father_name);
    postData.append("mother_name", values.mother_name);
    postData.append("father_email", values.father_email);
    postData.append("occupation", values.occupation);
    postData.append("father_number", values.father_number);
    postData.append("father_office", values.father_office);
    postData.append("father_office_number", values.father_office_number);
    postData.append("mother_email", values.mother_email);
    postData.append("mother_occupation", values.mother_occupation);
    postData.append("mother_number", values.mother_number);
    postData.append("mother_post", values.mother_post);
    postData.append("mother_office", values.mother_office);
    postData.append("mother_office_number", values.mother_office_number);
    postData.append("guardian_name", values.guardian_name);
    postData.append("guardian_number", values.guardian_number);
    postData.append("realtion", values.realtion);
    postData.append("guardian_occupation", values.guardian_occupation);
    postData.append("guardian_phone", values.guardian_phone);
    postData.append("country", values.country);
    postData.append("current_country", values.current_country);
    postData.append("current_state", values.current_state);
    postData.append("current_municipality", values.current_municipality);
    postData.append("current_district", values.current_district);
    postData.append("current_ward_no", values.current_ward_no);
    postData.append("current_street", values.current_street);
    postData.append("current_house_no", values.current_house_no);
    postData.append("ward_no", values.ward_no);
    postData.append("street", values.street);
    postData.append("house_no", values.house_no);
    postData.append("transport", values.transport);
    postData.append("hostel", values.hostel);
    postData.append("canteen", values.canteen);
    postData.append("referral_name", values.referral_name);
    postData.append("refferal_contact", values.refferal_contact);
    postData.append("reffereal_relation", values.reffereal_relation);
    postData.append("refferal_email", values.refferal_email);
    postData.append("images[student_image]", values.images.student_image[0]);
    postData.append(
      "images[student_signature]",
      values.images.student_signature[0]
    );
    postData.append(
      "previous_school",
      values.previous_school
    );
    postData.append(
      "previous_school_type",
      values.previous_school_type
    );
    postData.append(
      "previous_gpa",
      values.previous_gpa
    );
    postData.append(
      "want_to_become_first",
      values.want_to_become_first
    );
    postData.append(
      "want_to_become_second",
      values.want_to_become_second
    );
    postData.append(
      "pursue_after_plus2",
      values.pursue_after_plus2
    );
    postData.append(
      "good_sporting_facility",
      values.good_sporting_facility
    );
    postData.append(
      "best_faculty",
      values.best_faculty
    );
    postData.append(
      "subdomain",
      values.subdomain
    );
    // postData.append(`images[pdfs][][birth_certificate]`, values.images.pdfs[0].birth_certificate[0])
    // postData.append(`images[pdfs][][character_certificate]`, values.images.pdfs[1].character_certificate[0])
    // postData.append(`images[pdfs][][application_letter]`, values.images.pdfs[2].application_letter[0])
    // postData.append(`images[pdfs][][others]`, values.images.pdfs[3].others[0])
    {
      values?.images?.pdfs[0]?.birth_certificate !== undefined &&
      values?.images?.pdfs[0]?.birth_certificate?.length > 0
        ? postData.append(
            `images[pdfs][][birth_certificate]`,
            values.images.pdfs[0].birth_certificate[0]
          )
        : postData.append(`images[pdfs][][birth_certificate]`, " ");
    }
    {
      values?.images?.pdfs[1]?.character_certificate !== undefined &&
      values?.images?.pdfs[1]?.character_certificate?.length > 0
        ? postData.append(
            `images[pdfs][][character_certificate]`,
            values.images.pdfs[1].character_certificate[0]
          )
        : postData.append(`images[pdfs][][character_certificate]`, " ");
    }
    {
      values?.images?.pdfs[2]?.application_letter !== undefined &&
      values?.images?.pdfs[2]?.application_letter?.length > 0
        ? postData.append(
            `images[pdfs][][application_letter]`,
            values.images.pdfs[2].application_letter[0]
          )
        : postData.append(`images[pdfs][][application_letter]`, " ");
    }
    {
      values?.images?.pdfs[3]?.others !== undefined &&
      values?.images?.pdfs[3]?.others?.length > 0
        ? postData.append(
            `images[pdfs][][others]`,
            values.images.pdfs[3].others[0]
          )
        : postData.append(`images[pdfs][][others]`, " ");
    }

    PostAdmission(postData)
      .then((res) => {
        if (res.status === 200) {
          toast.success("form submitted successfully");
          setSubmitting(false);
          // handlePdfDownload(values?.printRef && values?.printRef);
          const element = values?.printRef.current;
          const options = {
            filename: "document.pdf",
            image: { type: "jpeg", quality: 0.98 },
            margin: [0.6, 0, 0.6, 0],
            html2canvas: {
              scale: 2,
              dpi: 192,
              logging: true,
              letterRendering: true,
              useCORS: true,
            },
            jsPDF: {
              unit: "in",
              format: "a4",
              orientation: "portrait",
              borderColor: "#219ebc", // border color
              borderWidth: 1,
            },
            pagebreak: { mode: "avoid-all", before: ".page-break" },
          };
          html2pdf().from(element).set(options).save();
          history.push("/thankyou");
        }
      })
      .catch((err) => {
        let errorMessage = "Failed to proceed";
        if (err.message) {
          errorMessage = err.message;
        }
        // if (err.response.data.message) {
        //   errorMessage = err.response.data.message;
        // }
        showErrorMessage(errorMessage);
      });
  };

  return (
    <Formik
      initialValues={{ ...initialValues, printRef: React.useRef() }}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {children}
    </Formik>
  );
};

export const FormikErrorMessage = ({ name }) => {
  return <ErrorMessage name={name} component="div" className="error" />;
};
