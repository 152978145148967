import { ErrorMessage, Field, yupToFormErrors } from "formik";
import { useState, useEffect } from "react";
import {
  FormikErrorMessage,
  useAdmissionFormFormikContext,
} from "../HorizontalStepper.js/FormikWrapper";
import "./PersonalInfo.scss";
import AdimssionFormHead from "../AdmissionForm/AdmissionFormHead/AdimssionFormHead";
import { NepaliDatePicker } from "nepali-datepicker-reactjs";
import "nepali-datepicker-reactjs/dist/index.css";
import BikramSambat, { ADToBS, BSToAD } from "bikram-sambat-js";
import {
  BLOOD_GROUP,
  CASTE,
  COUNTRY,
  GENDER,
  GRADE,
  RELIGION,
} from "../../utils/constant";
import { Navbar } from "react-bootstrap";

export const PersonalInfo = ({ schoolName, address, logo, email1, contact1, subdomain }) => {
  const { getFieldProps, setFieldValue, values } =
    useAdmissionFormFormikContext();
  const [gradeOption, setGradeOption] = useState("");
  const [nationalityOption, setNationalityOption] = useState("");
  const [currentDate, setCurrentDate] = useState(new Date());
  const [addob, setAdDob] = useState("");
  const [age, setAge] = useState("");
  useEffect(() => {
    setFieldValue("subdomain", subdomain);
  },[subdomain])
  useEffect(() => {
    const currentDate = new Date();
    const birthDate = new Date(addob);
    const diff = currentDate.getTime() - birthDate.getTime();
    const ageInYears = Math.floor(diff / (1000 * 60 * 60 * 24 * 365.25));
    setAge(ageInYears);
    setFieldValue("age", ageInYears);
  }, [addob, setFieldValue]);

  useEffect(() => {
    const twoYearsFromNow = new Date(
      currentDate.getFullYear() + 2,
      currentDate.getMonth(),
      currentDate.getDate()
    );
    setCurrentDate(twoYearsFromNow.toISOString().slice(0, 10));
  }, []);

  useEffect(()=>{
    const filteredGrade = GRADE?.filter((item)=> item?.label === "Grade 11")
    setFieldValue("grade_token", filteredGrade[0]?.value);
  },[])

  useEffect(() => {
    if (values.dob) {
      const adDate = new BikramSambat(values.dob, "BS").toAD();
      setFieldValue("ADdob", adDate);
      setAdDob(adDate);
    }
  }, [values.dob, setFieldValue]);

  return (
    <div className="personal-info">
      <div className="section-gap">
        <div className="container">
          <div className="admission-form">
            <AdimssionFormHead
              schoolName={schoolName}
              address={address}
              logo={logo}
              email={email1}
              contact={contact1}
            />
            <p className="tag main-content">Fields with (*) are compulsory.</p>

            <div className="option-head">
              <div className="field-input-block">
                <p className="main-content uppercase">
                  Grade Applied For :<span className="star">*</span>
                </p>
                <Field
                  as="select"
                  className="field-input"
                  name="grade_token"
                  onChange={(e) => {
                    setFieldValue("grade_token", e.target.value);
                    setGradeOption(e.target.value);
                  }}
                  // value={gradeOption}
                  value={values.grade_token}
                >
                  {/* <option value="" disabled>
                    --Select--
                  </option> */}
                  {GRADE?.map((item, index) => {
                    return (
                      <>
                        <option key={index} value={item.value}>
                          {item.label}
                        </option>
                      </>
                    );
                  })}
                </Field>
                <FormikErrorMessage name="grade_token" />
              </div>
              <div className="uppercase small-title center">
                Personal Information
              </div>
            </div>

            <div className="text-initial">
              <p className="main-content name-tag">
                Name of Applicant (Full Name):
                <span className="star">*</span>
              </p>
              <div className="row">
                <div className="col-md-4">
                  <div className="field-input-block">
                    <input
                      className="field-input text-capitalize"
                      placeholder="First Name"
                      name="student_name"
                      {...getFieldProps("student_name")}
                      onChange={(e) => {
                        setFieldValue("student_name", e.target.value);
                      }}
                    />

                    {/* {yupToFormErrors.Field && (
                      <FormikErrorMessage name="student_name" />
                    )} */}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="field-input-block">
                    <input
                      className="field-input text-capitalize"
                      placeholder="Middle Name"
                      name="middleName"
                      {...getFieldProps("middleName")}
                      onChange={(e) => {
                        setFieldValue("middleName", e.target.value);
                      }}
                    />
                    <FormikErrorMessage name="middleName" />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="field-input-block">
                    <input
                      className="field-input text-capitalize"
                      placeholder="Last Name"
                      name="lastName"
                      {...getFieldProps("lastName")}
                      onChange={(e) => {
                        setFieldValue("lastName", e.target.value);
                      }}
                    />
                    <FormikErrorMessage name="lastName" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="field-input-block start-field">
                    <p className="main-content">
                      Date of Birth:
                      <span className="star">*</span>
                    </p>
                    <div className="d-column date-section">
                      <div className="d-flex date">
                        <span className="main-content">BS</span>
                        <NepaliDatePicker
                          inputClassName="form-control"
                          {...getFieldProps("dob")}
                          onChange={(value) => {
                            setFieldValue("dob", value);
                          }}
                          options={{ calenderLocale: "en", valueLocale: "en" }}
                        />
                      </div>
                      <FormikErrorMessage name="dob" />

                      <div className="d-flex">
                        <span className="main-content">AD</span>
                        <input
                          className="field-input"
                          type="date"
                          name="ADdob"
                          max={currentDate}
                          {...getFieldProps("ADdob")}
                          onChange={(e) => {
                            setFieldValue("ADdob", e.target.value);
                          }}
                        />
                      </div>
                      <FormikErrorMessage name="ADdob" />
                    </div>
                  </div>
                </div>
                <div className="col-md-6 d-column gender-row">
                  <div className="radio-tab d-flex">
                    <p className="main-content name-tag">
                      Gender:
                      <span className="star">*</span>
                    </p>
                    <div className="d-flex radio-block">
                      {GENDER?.map((item, index) => {
                        return (
                          <div className="radio-label d-flex">
                            <input
                              key={index}
                              type="radio"
                              // value={item}
                              name="gender"
                              checked={item === values.gender}
                              onChange={(e) => {
                                setFieldValue("gender", item);
                              }}
                              // value={values.gender}
                            />
                            <label className="main-content">{item}</label>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <FormikErrorMessage name="gender" />

                  <div className="field-input-block">
                    <div className="d-flex">
                      <p className="main-content name-tag">Age:</p>
                      <div className="age-block">
                        <input
                          disabled
                          className="field-input"
                          name="age"
                          {...getFieldProps("age")}
                          // onChange={(e) => {
                          //   setFieldValue("age", e.target.value);
                          // }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="field-input-block">
                    <p className="main-content name-tag">
                      Nationality:
                    </p>
                    <Field
                      as="select"
                      className="field-input"
                      name="nationality"
                      onChange={(e) => {
                        setFieldValue("nationality", e.target.value);
                        setNationalityOption(e.target.value);
                      }}
                      value={values.nationality}
                    >
                      <option value="" disabled>
                        --Select--
                      </option>
                      {COUNTRY?.map((item) => {
                        return (
                          <>
                            <option key={item} value={item}>
                              {item}
                            </option>
                          </>
                        );
                      })}
                    </Field>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="field-input-block">
                    <p className="main-content name-tag">
                      Email:<span className="star">*</span>
                    </p>
                    <input
                      className="field-input"
                      name="email"
                      {...getFieldProps("email")}
                      onChange={(e) => {
                        setFieldValue("email", e.target.value);
                      }}
                    />
                    <FormikErrorMessage name="email" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="field-input-block">
                    <p className="main-content name-tag">
                      Phone (Res.):
                    </p>
                    <input
                      className="field-input"
                      name="phone"
                      type="number"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onChange={(e) => {
                        setFieldValue("phone", e.target.value);
                      }}
                      {...getFieldProps("phone")}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="field-input-block">
                    <p className="main-content name-tag">
                      Mobile No:
                      <span className="star">*</span>
                    </p>
                    <input
                      className="field-input"
                      type="number"
                      name="mobile"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onChange={(e) => {
                        setFieldValue("mobile", e.target.value);
                      }}
                      {...getFieldProps("mobile")}
                    />
                    <FormikErrorMessage name="mobile" />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="field-input-block">
                    <p className="main-content name-tag">Religion:</p>
                    <Field
                      as="select"
                      className="field-input"
                      name="religion"
                      onChange={(e) => {
                        setFieldValue("religion", e.target.value);
                      }}
                      {...getFieldProps("religion")}
                    >
                      <option value="" disabled>
                        --Select--
                      </option>
                      {RELIGION?.map((item) => {
                        return (
                          <option key={item} value={item}>
                            {item}
                          </option>
                        );
                      })}
                      <FormikErrorMessage name="religion" />
                    </Field>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="field-input-block">
                    <p className="main-content name-tag">Caste / Ethnicity:
                    <span className="star">*</span>
                    </p>
                    <Field
                      as="select"
                      className="field-input"
                      name="ethnicity"
                      onChange={(e) => {
                        setFieldValue("ethnicity", e.target.value);
                      }}
                    >
                      <option value="" disabled>
                        --Select--
                      </option>
                      {CASTE?.map((item) => {
                        return (
                          <option key={item} value={item}>
                            {item}
                          </option>
                        );
                      })}
                      <FormikErrorMessage name="ethnicity" />
                    </Field>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="field-input-block">
                    <p className="main-content name-tag">Blood Group:</p>
                    <Field
                      as="select"
                      className="field-input"
                      name="blood_group"
                      onChange={(e) => {
                        setFieldValue("blood_group", e.target.value);
                      }}
                    >
                      <option value="" disabled>
                        --Select--
                      </option>
                      {BLOOD_GROUP?.map((item) => {
                        return (
                          <option key={item} value={item}>
                            {item}
                          </option>
                        );
                      })}
                      <FormikErrorMessage name="blood_group" />
                    </Field>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
