import React from "react";
import "./StepperBottom.scss";
// import loader from "../../images/loader3.gif"
import loader from "../../images/loader.gif";
import { useAdmissionFormFormikContext } from "../HorizontalStepper.js/FormikWrapper";

export const StepperBottom = ({
  showBackButton,
  nextText = "Go to Next",
  backText = "Go to Prev",
  cancelText = "Cancel",
  onNext,
  onBack,
  onCancel,
  disableNext = false,
  type,
}) => {
  const { isSubmitting } = useAdmissionFormFormikContext();
  return (
    <div className="stepper-bottom admission-form">
      <div className="stepper-bottom-block ">
        <div className="center next-btn">
          <div className="d-flex">
            {showBackButton && (
              <button onClick={onBack} className="main-content main-btn d-flex">
                <span className="next d-flex">
                  <i class="bx bx-chevron-left"></i>
                </span>
                {backText}
              </button>
            )}
            {/* {cancelText && (
          <button type="button" onClick={onCancel}>
            {cancelText}
          </button>
        )} */}
            <button
              type={nextText === "Save" ? "submit" : "button"}
              onClick={onNext}
              disabled={disableNext}
              className="main-content main-btn "
              disableNext
            >
              {nextText}
              {isSubmitting && <div className="loader"></div>}
              <span className="next d-flex">
                <i class="bx bx-chevron-right"></i>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
