import React, { useState, useEffect } from "react";
import "./Benefits.scss";
import { getBenefits } from "../Api/GetApi";

import { imgdomain } from "../Api/AxiosInstance";

function Benefits(props) {
  const [benefitsdata, setBenefitsData] = useState([]);
  // const [dataLength, setDataLength] = useState(4);

  useEffect(() => {
    getBenefits().then((res) => {
      let data = props.Benefits ? res.data.slice(0, 4) : res.data;
      setBenefitsData(data);
    });
    // window.scrollTo(0, 0);
  }, [props.Benefits]);
  return (
    <div className="benefits-section section-top">
      <div className="container">
        <h2 className="main-title" data-aos-once={true} data-aos="fade-down">
          Solutions made for you
        </h2>

        <div
          className="content-style"
          data-aos-once={true}
          data-aos="fade-left"
        ></div>
        <div className="benefit-content">
          <div className="row">
            {benefitsdata?.map((item, index) => {
              return (
                <div
                  className="col-md-6 col-lg-3"
                  key={index}
                  data-aos-once={true}
                  data-aos="fade-right"
                >
                  <div className="benefit-col">
                    <div
                      className="ben-icon "
                      style={{
                        backgroundColor: `${
                          item.color ? `${item.color}` : "#29abe21a"
                        }`,
                      }}
                    >
                      {imgdomain + item.logo ===
                      "https://storage.googleapis.com/axios-documents/null" ? (
                        <img
                          src={require("../../images/icons/trophy.svg").default}
                          alt="icon"
                        />
                      ) : (
                        <img src={imgdomain + item.logo} alt="icon" />
                      )}
                    </div>

                    <div className="benefit-sub-content">
                      <h2 className="title-content">{item.title}</h2>
                      <p className="content">{item.desc}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Benefits;
