import React from "react";
import "./Testimonals.scss";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Component } from "react";
import { getTestimonials } from "../Api/GetApi";
import { imgdomain } from "../Api/AxiosInstance";

class Testimonals extends Component {
  state = {
    testimonialdata: [],

    responsive: {
      0: {
        items: 1,
      },
      480: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };

  componentDidMount() {
    getTestimonials().then((res) => {
      this.setState({
        testimonialdata: res.data,
      });
    }, []);
  }

  render() {
    return (
      <div className="testimonal-section section-top ">
        <div className="container">
          <h2
            className="main-title position-title"
            data-aos-once={true}
            data-aos="fade-down"
          >
            What our clients say about us
          </h2>

          <div
            className="content-style"
            data-aos-once={true}
            data-aos="fade-left"
          ></div>

          <div className="swipe-section mgt-30">
            {this.state.testimonialdata.length && (
              <OwlCarousel
                className="owl-theme"
                responsive={this.state.responsive}
                loop
                // autoplay="true"
                // autoplayTimeout="1000"
                // autoplayHoverPause="true"
                margin={10}
                items={2}
                nav
                data-aos-once={true}
                data-aos="fade-right"
              >
                {this.state.testimonialdata.map((item, index) => {
                  return (
                    <div className="item">
                      <div className="swipe-content" key={index}>
                        <div className="row">
                          <div className="card-width">
                            <div className="width-60">
                              <div className="card-section">
                                <img
                                  className="app-img"
                                  src={require("../../images/kama.png").default}
                                  alt="images"
                                />
                                <div>
                                  <h4 className="title-content ">
                                    {item.title}
                                  </h4>
                                  <p className="content mg-botton-20">
                                    {item.description}
                                  </p>
                                  <div className="bottom-part">
                                    <div className="img-logo">
                                      <img
                                        className="logo-img"
                                        src={imgdomain + item.image}
                                        alt="img"
                                      />
                                    </div>
                                    <div className="info-details">
                                      <div className="bottom-text">
                                        <h4 className="title-content">
                                          {item.name}
                                        </h4>

                                        <div className="content no-mg-bottom">
                                          {item.desigation}
                                        </div>
                                      </div>

                                      <p className="content">{item.school}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="width-30">
                              <div className="card-info d-flex">
                                <img
                                  className="info-img"
                                  src={imgdomain + item.image}
                                  alt="img"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
                {/* 
              <div className="item">
                <div className="swipe-content">
                  <div className="card-section">
                    <h1 className="main-title">Wonderful Team</h1>
                    <p className="content">
                      Nivid went overboard with my expectations of a School
                      Management System. The integration of multiple features in
                      a single platform has made it easy for us to convert our
                      system to digital.
                    </p>
                    <div className="tri-clip"></div>
                  </div>
                  <div className="card-info d-flex">
                    <img
                      className="info-img"
                      src={require("../../images/ss.jpeg").default}
                      alt="img"
                    />
                    <div className="info-details">
                      <h1 className="main-title">Kumar Manandhar</h1>
                      <p className="content">Principal, Kavre School Banepa</p>
                    </div>
                  </div>
                </div>
              </div> */}
              </OwlCarousel>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Testimonals;
