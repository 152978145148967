import { createContext, useCallback, useContext } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

const initialValues = {
  showSuccessMessage: () => {},
  showErrorMessage: () => {},
};

const ToastContext = createContext(initialValues);

export const ToastProvider = ({ children }) => {
  const showSuccessMessage = useCallback((message) => {
    toast.success(message);
  }, []);

  const showErrorMessage = useCallback((message) => {
    toast.error(message);
  }, []);

  return (
    <ToastContext.Provider
      value={{
        showSuccessMessage,
        showErrorMessage,
      }}
    >
      <ToastContainer />
      {children}
    </ToastContext.Provider>
  );
};

export const useToast = () => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error("toast provider not found");
  }
  return context;
};
