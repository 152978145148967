import React from "react";
import "./Schoolcardslider.css";
import { imgdomain } from "../../../../../Api/AxiosInstance";
import { useHistory } from "react-router-dom";

const Schoolcardslider = (props) => {
  // const { id, image, name, address } = props;

  // const history = useHistory()

  // const changeSchool = () => {
  //   history.push(`/school-info?id=${id}&school_name=${name}&address=${address}&logo=${image}`);
  // };

  return (
    <>
      <div className="owl-card">
      <div className="owl-card-wrapper">
          <img
            src={imgdomain + props.image}
            alt="school logo"
            className="owl-img"
          />
          <p className="owl-title">{props.name}</p>
          <p className="owl-content">{props.address}</p>
        </div>
      </div>
    </>
  );
};

export default Schoolcardslider;
