import React from "react";
import Banner from "../Banner/Banner";
import "./ProductPage.scss";
import Demo from "../Demo/Demo";
import MetaConfigure from "../helmet/helmet";
function ProductPage() {
  return (
    <div className="product-section">
      <MetaConfigure title="An excellent solution that includes a web app, a teachers app, and a parents app" />

      <div className="banner-half">
        <Banner
          type="show"
          page="Services"
          title="Our Product"
          subTitle="We have high-quality products that incorporate the most recent and advanced IT advances, as well as a clean design and user-friendly interface. The software is reliable, secure, easy to use, and highly scalable in a timely manner."
        />
      </div>

      <div className="container">
        <div className="row mgt-30 text-init ">
          <div className="col-md-6 alignCenter">
            <div className="product-contain  ">
              <h1 className="main-title">Web App</h1>

              <p className="content">Control overall activities</p>

              <div className="d-flex">
                <i class="bi bi-check-lg"></i>
                <p>
                  Accounts payable, Accounts receivable, Marksheet, Printable
                  bills, Data Visualization and Reports
                </p>
              </div>

              <div className="d-flex">
                <i class="bi bi-check-lg"></i>
                <p>
                  Accounting, Library, Examination, Inventory, Canteen
                  Management System and Bus Tracking System
                </p>
              </div>

              <div className="d-flex">
                <i class="bi bi-check-lg"></i>
                <p>
                  Daily Student activity report like Attendance, Assignment ,
                  Messages, and Notice
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <img
              className="fullwidth"
              src={require("../../images/dashboard.png").default}
              alt="img"
            />
          </div>
        </div>

        <div className="row mgt-30 text-init">
          <div className="col-md-6">
            <img
              className="fullwidth"
              src={require("../../images/mobileapp.png").default}
              alt="img"
            />
          </div>

          <div className="col-md-6 alignCenter">
            <div className="product-contain  ">
              <h1 className="main-title">NIVID</h1>

              <p className="content">Mobile App for Students</p>

              <div className="d-flex">
                <i class="bi bi-check-lg"></i>
                <p>
                  Marksheet Chart, Assignment upload, Remarks, Student Details
                  and Leave Note
                </p>
              </div>

              <div className="d-flex">
                <i class="bi bi-check-lg"></i>
                <p>
                  Due Fee, Exam Routine, Bus Tracking Calendar and Notice Wall
                </p>
              </div>

              <div className="d-flex">
                <i class="bi bi-check-lg"></i>
                <p>Real Time Notification for online classes and messages</p>
              </div>
            </div>
          </div>
        </div>

        <div className="row  mgt-30 text-init">
          <div className="col-md-6 alignCenter">
            <div className="product-contain  ">
              <h1 className="main-title">NIVID Guru</h1>

              <p className="content">Mobile App for Teachers</p>

              <div className="d-flex">
                <i class="bi bi-check-lg"></i>
                <p>Attendance, Assignment, Remark and Logbook</p>
              </div>

              <div className="d-flex">
                <i class="bi bi-check-lg"></i>
                <p>Notice Wall, Chat, Mark Entry and Calendar</p>
              </div>

              <div className="d-flex">
                <i class="bi bi-check-lg"></i>
                <p>Online Classes, Plans and Report</p>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <img
              className="fullwidth"
              src={require("../../images/nividguru.png").default}
              alt="img"
            />
          </div>
        </div>

        <div className="row mgt-30 text-init ">
          <div className="col-md-6">
            <img
              className="fullwidth"
              src={require("../../images/nividportal.png").default}
              alt="img"
            />
          </div>

          <div className="col-md-6 alignCenter">
            <div className="product-contain  ">
              <h1 className="main-title">NIVID Portal</h1>

              <p className="content">
                A Portal to conduct Online Classes and Examination
              </p>

              <div className="d-flex">
                <i class="bi bi-check-lg"></i>
                <p>Online Portal for admin, Teacher and Student</p>
              </div>

              <div className="d-flex">
                <i class="bi bi-check-lg"></i>
                <p>Auto checking objective, Subjective online class</p>
              </div>

              <div className="d-flex">
                <i class="bi bi-check-lg"></i>
                <p>Auto attendance for both Teacher and Students</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Demo
        title="We'd love to demonstrate what we can do for you"
        btn_text="Request Demo"
        btn_url="/demo"
      />
    </div>
  );
}

export default ProductPage;
