import React from "react";
import Banner from "../../components/Banner/Banner";
import ZoomIntegrationComponent from "../../components/ZoomIntegration/ZoomIntegrationComponent";
import Demo from "../../components/Demo/Demo";
const ZoomIntegrationPage = () => {
  // window.scrollTo(0, 0);
  return (
    <>
      <div className="banner-half">
        <Banner
          type="show"
          page="Zoom Integration"
          title="Zoom Integration"
          subTitle="NIVID app integration with zoom"
        />
      </div>
      <ZoomIntegrationComponent />
      <Demo
        title="We'd love to demonstrate what we can do for you"
        btn_text="Request Demo"
        btn_url="/demo"
      />
    </>
  );
};
export default ZoomIntegrationPage;
