import React from "react";
import Banner from "../Banner/Banner";
import Contact from "../Contact/Contact";
import Demo from "../Demo/Demo";
import MetaConfigure from "../helmet/helmet";
function ContactPage() {
  return (
    <div>
      <div className="banner-half">
        <MetaConfigure title="ContactUs-Nivid" />

        <Banner
          type="show"
          title="Contact Us"
          subTitle="NIVID School Management System"
        />
      </div>

      <Contact />
      <Demo
        title="We'd love to demonstrate what we can do for you"
        btn_text="Request Demo"
        btn_url="/demo"
      />
    </div>
  );
}

export default ContactPage;
