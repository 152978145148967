import "./App.css";
import "../src/styles/main.scss";

// import Demo from "./components/Demo/Demo";
import Footer from "./components/Footer/Footer";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from "react-router-dom";
import NavbarList from "./components/Navbar/Navbar";
import Aboutus from "./components/Aboutus/Aboutus";
import FeaturePage from "./components/FeaturePage/FeaturePage";
import SubFeature from "./components/SubFeature/SubFeature";
import ClientPage from "./components/ClientPage/ClientPage";
import ProductPage from "./components/ProductPage/ProductPage";
import FAQPage from "./components/FAQPage/FAQPage";
import Home from "./components/Home/Home";
import ContactPage from "./components/ContactPage/ContactPage";
import DemoPage from "./components/DemoPage/DemoPage";
import RelatedFeature from "./components/RelatedFeature/RelatedFeature";
import PrivacyPolicy from "./Pages/Privacy/Pricacy";
import TermOfUsePage from "./Pages/TermOfUse/TermOfUsePage";
import ZoomIntegrationPage from "./Pages/ZoomIntegration/ZoomIntegrationPage";
import { useEffect, useState } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import OurClient from "./components/OurClients/OurClients";
import { AdmissionForm } from "./components/AdmissionForm/AdmissionForm";
import Schoolinfo from "./components/OurClients/Schoolinfo/Schoolinfo";
import Thankyou from "./components/Lastpage/Thankyou";
import Schoollist from "./components/SchoolListM/Schoollist";
import FloatingAdmissionForm from "./components/SchoolListM/FloatingAdmissionForm";

function App() {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  

  return (
    <Router>
      <div className="App">
        <Switch>
          <Route
            exact
            path={[
              "/",
              "/school",
              "/about-us",
              "/features",
              "/clients-page",
              "/school/:slug",
              "/school",
              "/our-clients",
              "/products",
              "/faq",
              "/features/:slug",
              "/Contactus",
              "/demo",
              "/privacy",
              "/term-of-use",
              "/zoom-integration",
              "/thankyou",
              "/schoollist",
            ]}
          >
            <NavbarList />
          </Route>
        </Switch>
        <Switch>
          {" "}
          <Route
            exact
            path={[
              "/",
              "/school",
              "/about-us",
              "/features",
              "/clients-page",
              "/school/:slug",
              "/school",
              "/our-clients",
              "/products",
              "/faq",
              "/features/:slug",
              "/Contactus",
              "/demo",
              "/privacy",
              "/term-of-use",
              "/zoom-integration",
              "/thankyou",
            ]}
          >
            <FloatingAdmissionForm />
          </Route>
        </Switch>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>

          <Route exact path="/about-us">
            <Aboutus />
          </Route>

          <Route exact path="/features">
            <FeaturePage />
          </Route>

          <Route exact path="/admissionForm">
            <AdmissionForm />
          </Route>

          <Route exact path="/features/:slug">
            <SubFeature />
          </Route>

          <Route exact path="/clients-page">
            <ClientPage />
          </Route>

          <Route exact path="/school/:slug">
            <Schoolinfo />
          </Route>

          <Route exact path="/our-clients">
            <OurClient />
          </Route>

          <Route exact path="/products">
            <ProductPage />
          </Route>

          <Route exact path="/faq">
            <FAQPage />
          </Route>
          <Route exact path="/features/:slug">
            <RelatedFeature />
          </Route>

          <Route exact path="/Contactus">
            <ContactPage />
          </Route>

          <Route exact path="/demo">
            <DemoPage />
          </Route>
          <Route exact path="/privacy">
            <PrivacyPolicy />
          </Route>
          <Route exact path="/term-of-use">
            <TermOfUsePage />
          </Route>
          <Route exact path="/zoom-integration">
            <ZoomIntegrationPage />
          </Route>
          <Route exact path="/thankyou">
            <Thankyou />
          </Route>
          <Route exact path="/schoollist">
            <Schoollist />
          </Route>
        </Switch>
        {/* <Demo /> */}
        <Footer />
      </div>
    </Router>
  );
}

export default App;
