import React from "react";
import "./Schoolcard.css";
import {imgdomain} from '../../../../Api/AxiosInstance'
import { useHistory } from "react-router-dom";



const Schoolcard = (props) => {
  const { id, image, name, address } = props;

  const history = useHistory()

  const changeSchool = () => {
    history.push(`/school-info?id=${id}&school_name=${name}&address=${address}&logo=${image}`);
  };

  return (
    <>
      <div className="schoolcard"
                data-aos-once={true}
                data-aos="fade-right">
        <div className="schoolcard-items" onClick={() => changeSchool(id)} >
          <img src={imgdomain + image} alt="school logo" />
          <div className="schoolcard-contents">
            <h1>{name}</h1>
            <h2>{address}</h2>
          </div>
        </div>
      </div>
    </>
  );
};

export default Schoolcard;
