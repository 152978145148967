import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useAdmissionFormFormikContext } from "../HorizontalStepper.js/FormikWrapper";
import "./Dropzone.scss";

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 200,
  height: 200,
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
  width: "100%",
};

const img = {
  display: "block",
  width: "100%",
  height: "100%",
};

export function Preview(props) {
  const { values } = useAdmissionFormFormikContext();
  const [files, setFiles] = useState([]);
  const [disable, setDisable] = useState(false);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
    },
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });
  useEffect(() => {
    if (props?.fileImg?.length > 0) {
      setFiles(props?.fileImg);
    }
  }, [props?.fileImg]);
  useEffect(() => {
    props?.setFilesList?.(files);
  }, [files]);

  useEffect(() => {
    props?.setFilesList?.(files);
  }, [files]);

  const removeImg = () => {
    setFiles([]);
    setDisable(true);
  };
  const thumbs = files.map((file) => {
    return (
      <div style={thumb} key={file.name}>
        <div style={thumbInner}>
          <img
            src={file.preview}
            style={img}
            alt="std-img"
            onLoad={() => {
              // URL.revokeObjectURL(file.preview);
              URL.createObjectURL(file);
            }}
          />
        </div>
      </div>
    );
  });

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, []);

  return (
    <div className=" dropzone-section">
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps({ disable })} />

        {files.length === 0 ? (
          <img
            className="upload-img"
            src={require("../../images/feature-img/emptyimage.png").default}
            alt="test"
          />
        ) : (
          <div className="selected-image">
            <aside className="sadas">{thumbs}</aside>
          </div>
        )}
      </div>
      <div>
        {/* <i
          class="bx bx-trash"
          onClick={(e) => {
            e.preventDefault();
            removeImg();
          }}
        ></i> */}
        {files.length === 0 ? (
          <></>
        ) : (
          <div className="trash-icon">
            <i
              class="bx bx-trash"
              onClick={(e) => {
                e.preventDefault();
                removeImg();
              }}
            ></i>
          </div>
        )}
      </div>
    </div>
  );
}
