import React, { useEffect, useState } from "react";
import "./PreviewDetails.css";
import AdimssionFormHead from "../AdmissionForm/AdmissionFormHead/AdimssionFormHead";
import {
  FormikWrapper,
  useAdmissionFormFormikContext,
} from "../HorizontalStepper.js/FormikWrapper";
import { HiOutlineFolderDownload } from "react-icons/hi";
import { imgdomain } from "../Api/AxiosInstance";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import html2pdf from "html2pdf.js";
import { handlePdfDownload } from "../../utils/services/downloadService";
import { getDistrict, getMunicipality, getProvince } from "../Api/GetApi";

function PreviewDetails({ schoolName, address, logo, email1, contact1, subdomain }) {
  const { values } = useAdmissionFormFormikContext();
  const [province, setProvince] = useState();
  const [selectedprovince, setSelectedProvince] = useState();
  const [selectedDistrict, setSelectedDistrict] = useState();
  const [selectedCurrentProvince, setSelectedCurrentProvince] = useState();
  const [selectedCurrentDistrict, setSelectedCurrentDistrict] = useState();

  // const studentimgpreview = URL.createObjectURL(
  //   values?.images?.student_image?.[0]
  // );
  // if (studentimgpreview === "") {
  //   alert("upload the photo");
  // }
  useEffect(() => {
    getProvince().then((res) => {
      setProvince(res?.data);
    });
  }, []);

  const calldistrict = (provinceid) => {
    getDistrict(provinceid).then((res) => {
      const findDistrict = res?.data?.find(
        (item) => item?.id.toString() === values.district
      );
      const findCurrentDistrict = res?.data?.find(
        (item) => item?.id.toString() === values.current_district
      );

      setSelectedCurrentDistrict(findCurrentDistrict);
      setSelectedDistrict(findDistrict);
    });
  };

  useEffect(() => {
    const findProvince = province?.find(
      (item) => item.id.toString() === values.state
    );
    const findCurrentProvince = province?.find(
      (item) => item.id.toString() === values.current_state
    );
    setSelectedCurrentProvince(findCurrentProvince);
    setSelectedProvince(findProvince);
  }, [province]);

  useEffect(() => {
    calldistrict(values.state);
  }, [values.state]);

  return (
    <>
      <div className="previewpage-container">
        <AdimssionFormHead
          schoolName={schoolName}
          address={address}
          logo={logo}
          email={email1}
          contact={contact1}
        />
        <div className="previewpage-headings">Preview & Post</div>

        <div className="previewpage-background">
          <div className="preview-download">
            <button onClick={() => handlePdfDownload(values)}>
              Download Pdf <HiOutlineFolderDownload size={30} />
            </button>
          </div>

          <div className="containerr">
            <div ref={values.printRef} className="previewpage-contents">
              <div className="schooldetails">
                <img src={logo} alt="img" style={{height: "110px", width: "110px", objectFit: "contain"}} />
                <div className="namedetails">
                  <h1 style={{marginBottom: "0"}}>{schoolName}</h1>
                  <h2 style={{marginBottom: "0"}}>{address}</h2>
                  <h2 style={{marginBottom: "0"}}>{contact1} | {email1}</h2>
                </div>
              </div>

              <div className="preview-items">
                <div className="previewpage-items">
                <div className="row">
                  <div className="col-md-12">
                    <h1>Personal Info</h1>
                  </div>
                  <div className="col-md-12">
                    <label className="text-capitalize">
                      
                      <span> Student's Name : </span>
                      {values.student_name +
                        " " +
                        values.middleName +
                        " " +
                        values.lastName}
                    </label>
                  </div>

                  {values.grade_token &&
                    <div className="col-md 6">
                      <label>
                        
                        <span>Grade : </span> {values.grade_token}
                      </label>
                    </div>
                  }

                  {values.gender &&
                    <div className="col-md-6">
                      <label>  
                        <span>Gender : </span> {values.gender}
                      </label>
                    </div>
                  }

                  <div className="col-md-6">
                    <label>
                      <span>Date Of Birth : </span> {values.dob}
                    </label>
                  </div>

                  <div className="col-md-6">
                    <label>
                      <span>Age : </span> {values.age}
                    </label>
                  </div>

                  <div className="col-md-6">
                    <label>
                      <span>Nationality : </span> {values.nationality}
                    </label>
                  </div>

                  <div className="col-md-6">
                    <label>
                      <span>Email : </span> {values.email}
                    </label>
                  </div>

                  {values.phone&&
                    <div className="col-md-6">
                      <label>
                        <span>Phone No : </span> {values.phone}
                      </label>
                    </div>
                  }

                  <div className="col-md-6">
                    <label>
                      <span>Mobile No : </span> {values.mobile}
                    </label>
                  </div>

                  {values.religion&&
                    <div className="col-md-6">
                      <label>
                        <span> Religion : </span>
                        {values.religion}
                      </label>
                    </div>
                  }

                  <div className="col-md-6">
                    <label>
                      <span>Ethnicity : </span> {values.ethnicity}
                    </label>
                  </div>

                  {values.blood_group &&
                    <div className="col-md-6">
                      <label>
                        <span>Blood Group : </span> {values.blood_group}
                      </label>
                    </div>
                  }

                  {values.father_name || values.mother_name || values.guardian_name ?
                  <>
                    <div className="col-md-12">
                      <h1>Parent's Info</h1>
                    </div>
                    <div className="col-md-6">
                    <h2>Father Info</h2>
                    {values.father_name &&
                      <div className="col-md-12">
                        <label>
                          <span>Father's Name : </span>
                          {values.father_name}
                        </label>
                      </div>
                    }
                  
                    {values.father_email &&
                      <div className="col-md-12">
                        <label>
                          <span>Father's Email : </span>
                          {values.father_email}
                        </label>
                      </div>
                    }

                  {values.occupation &&
                    <div className="col-md-12">
                      <label>
                        <span>Father's Occupation : </span>
                        {values.occupation}
                      </label>
                    </div>
                  }

                  {values.father_number&&
                    <div className="col-md-12">
                      <label>
                        <span>Father's Number : </span>
                        {values.father_number}
                      </label>
                    </div>
                  }
                  
                  {values.father_office &&
                    <div className="col-md-12">
                      <label>
                        <span>Father's Office : </span>
                        {values.father_office}
                      </label>
                    </div>
                  }
                  {values.father_post &&
                    <div className="col-md-12">
                      <label>
                        <span>Father Post : </span>
                        {values.father_post}
                      </label>
                    </div>
                  }
                  {values.father_office_number &&
                    <div className="col-md-12">
                      <label>
                        <span> Father's Office Phone No : </span>
                        {values.father_office_number}
                      </label>
                    </div>
                  }
                  </div>
                  <div className="col-md-6">
                    <h2>Mother Info</h2>
                  {values.mother_name &&
                    <div className="col-md-12">
                      <label>
                        <span>Mother's Name : </span>
                        {values.mother_name}
                      </label>
                    </div>
                  }
                  {values.mother_email&&
                    <div className="col-md-12">
                      <label>
                        <span>Mother's Email : </span>
                        {values.mother_email}
                      </label>
                    </div>
                  }
                  {values.mother_occupation &&
                    <div className="col-md-12">
                      <label>
                        <span>Mother's Occupation : </span>
                        {values.mother_occupation}
                      </label>
                    </div>
                  }
                  {values.mother_number&&
                    <div className="col-md-12">
                      <label>
                        <span>Mother's Number : </span>
                        {values.mother_number}
                      </label>
                    </div>
                  }
                  {values.mother_post &&
                    <div className="col-md-12">
                      <label>
                        <span>Mother's Post : </span>
                        {values.mother_post}
                      </label>
                    </div>
                  }
                  {values.mother_office&&
                    <div className="col-md-12">
                      <label>
                        <span>Mother's Office : </span>
                        {values.mother_office}
                      </label>
                    </div>
                  }
                  {values.mother_office_number &&
                    <div className="col-md-12">
                      <label>
                        <span>Mother's Office No : </span>
                        {values.mother_office_number}
                      </label>
                    </div>
                  }
                  </div>
                  {values.guardian_name &&
                    <div className="col-md-6">
                    <h2>Guardian Info</h2>
                    <div className="col-md-12">
                      <label>
                        <span>Guardian Name : </span>
                        {values.guardian_name}
                      </label>
                    </div>
                    {values.guardian_address&&
                    <div className="col-md-12">
                      <label>
                        <span>Guardian Address : </span>
                        {values.guardian_address}
                      </label>
                    </div>
                  }
                  {values.guardian_number&&
                    <div className="col-md-12">
                      <label>
                        <span>Guardian No : </span>
                        {values.guardian_number}
                      </label>
                    </div>
                  }
                  {values.realtion &&
                    <div className="col-md-12">
                      <label>
                        <span>Relations : </span>
                        {values.realtion}
                      </label>
                    </div>
                  }
                  {values.guardian_occupation &&
                    <div className="col-md-12">
                      <label>  
                        <span>Guardian Occupation : </span>
                        {values.guardian_occupation}
                      </label>
                    </div>
                  }
                  {values.guardian_phone &&
                    <div className="col-md-12">
                      <label>
                        <span>Guardian Phone No : </span> {values.guardian_phone}
                      </label>
                    </div>
                  }
                  </div>
                  }
                  </> : null}
                  <div className="clearfix"></div>
                  <div className="col-md-12">
                  <h1>Contact Info</h1>
                  </div>
                  <div className="col-md-6">
                  <h2>Permanent Address</h2>
                  <div className="col-md-12">
                  <label>
                    <span>Country : </span> {values.country}
                  </label>
                  </div>
                  <div className="col-md-12">
                  <label>
                    <span>State : </span>
                    {selectedprovince?.school_name}
                  </label>
                  </div>
                  <div className="col-md-12">
                  <label>
                    <span>District : </span>
                    {selectedDistrict?.school_name}
                  </label>
                  </div>
                  <div className="col-md-12">
                  <label>
                    <span>Municipality : </span>
                    {values.municipality}
                  </label>
                  </div>
                  
                  <div className="col-md-12">
                  <label>
                    <span>Ward No : </span>
                    {values.ward_no}
                  </label>
                  </div>
                  {values.street&&
                    <div className="col-md-12">
                      <label>
                        <span>Street No : </span>
                        {values.street}
                      </label>
                    </div>
                  }
                  {values.house_no&&
                  <div className="col-md-12">
                  <label>
                    <span>House No : </span>
                    {values.house_no}
                  </label>
                  </div>
                  }
                  </div>
                  <div className="col-md-6">
                  <h2>Temporary Address</h2>
                  <div className="col-md-12">
                    <label>
                      <span>Country : </span>
                      {values.current_country}
                    </label>
                  </div>
                  <div className="col-md-12">
                    <label>
                      <span>State : </span>
                      {selectedCurrentProvince?.school_name}
                    </label>
                  </div>
                  <div className="col-md-12">
                    <label>
                      <span>District : </span>
                      {selectedCurrentDistrict?.school_name}
                    </label>
                  </div>
                  <div className="col-md-12">
                    <label>
                      <span>Municipality : </span>
                      {values.current_municipality}
                    </label>
                  </div>
                  
                  <div className="col-md-12">
                    <label>
                      <span>Ward No : </span>
                      {values.current_ward_no}
                    </label>
                  </div>
                  {values.current_street&&
                    <div className="col-md-12">
                      <label>
                        <span>Street No : </span>
                        {values.current_street}
                      </label>
                    </div>
                  }
                  {values.current_house_no&&
                    <div className="col-md-12">
                      <label>
                        <span>House_No : </span>
                        {values.current_house_no}
                      </label>
                    </div>
                  }
                  </div>
                  <div className="col-md-12">
                  <h1>Other Info</h1>
                  </div>
                  <div className="col-md-6">
                  <label>
                    <span>Need Transport ? : </span> {values.transport}
                  </label>
                  </div>
                  <div className="col-md-6">
                  <label>
                    <span>Need Canteen ? : </span> {values.canteen}
                  </label>
                  </div>
                  <div className="col-md-6">
                  <label>
                    <span>Need Hostel ? : </span> {values.hostel}
                  </label>
                  </div>
                  <div className="col-md-6">
                  <label>
                    <span>Need good sporting facility? : </span> {values.good_sporting_facility? "Yes": "No"}
                  </label>
                  </div>
                  <div className="col-md-6">
                  <label>
                    <span>Need best faculty? : </span> {values.best_faculty? "Yes": "No"}
                  </label>
                  </div>
                  <div className="col-md-12">
                  <label>
                    <span>Previous School Name: </span>
                    {values.previous_school}
                  </label>
                  </div>
                  <div className="col-md-6">
                  <label>
                    <span>Previous School Type : </span>
                    {values.previous_school_type}
                  </label>
                  </div>
                  <div className="col-md-6">
                  <label>
                    <span>Previous GPA : </span>
                    {values.previous_gpa}
                  </label>
                  </div>
                  <div className="col-md-12">
                    <label><span>What do you want to become?</span></label>
                  </div>
                  {values.want_to_become_first || values.want_to_become_second ?
                  <>
                    <div className="col-md-6">
                      <label>
                        First Choice:
                        {values.want_to_become_first}
                      </label>
                    </div>
                    <div className="col-md-6">
                      <label>
                        Second Choice:
                        {values.want_to_become_second}
                      </label>
                    </div>
                  </> :
                  <div className="col-md-12">
                    <label>
                      Not Decided
                    </label>
                  </div>
                }
                  
                  {values.pursue_after_plus2 &&
                  <div className="col-md-12">
                  <label>
                    <span>Where do you want to pursue bachelor level of study?: </span>
                    {values.pursue_after_plus2}
                  </label>
                  </div>
                }
                  {values.referral_name ?<>
                  <label>
                    <span>Refferal's Name : </span>
                    {values.referral_name}
                  </label>
                  {values.refferal_contact&&
                  <label>
                    <span>Refferal's Contact : </span> {values.refferal_contact}
                  </label>
                  }
                  {values.reffereal_relation&&
                  <label>
                    <span>Refferal's Relation : </span>
                    {values.reffereal_relation}
                  </label>
                  }
                  {values.refferal_email&&
                  <label>
                    <span>Refferal's Contact : </span>
                    {values.refferal_email}
                  </label>
                  }
                  </>:null}
                </div>
                </div>
                {/* {studentimgpreview &&
                <div className="student-img">
                  <div className="student-img-items">
                    <img src={studentimgpreview} />
                  </div>
                </div>
              } */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PreviewDetails;
