import React, { useState, useEffect } from "react";
import Banner from "../Banner/Banner";
import "./OurClients.scss";
import { getClients } from "../Api/GetApi";
import { imgdomain } from "../Api/AxiosInstance";
import Demo from "../Demo/Demo";
import MetaConfigure from "../helmet/helmet";
import { useHistory } from "react-router-dom";
import { Container, InputAdornment, TextField } from "@mui/material";
import { AiOutlineSearch } from "react-icons/ai";

function OurClient() {
  const [clientdata, setClientData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const history = useHistory();

  const filteredClientData = clientdata.filter((item) =>
    item.school_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  function changepage(item) {
    history.push(`/school/${item?.slug}`);
  }

  useEffect(() => {
    getClients().then((res) => {
      setClientData(res.data);
    });
  }, []);
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
   

  return (
    <>
      <div className="ourclient-section">
        <MetaConfigure title="Digitize more than 100 + schools around Nepal" />

        <div className="banner-half">
          <Banner
            type="show"
            page="Clients"
            title="Our Valuable Clients"
            subTitle="NIVID School Management System"
          />
        </div>
        <div className="container">
          <div className="schoolSearch">
            <div className="search-container">
              <Container maxWidth="md" sx={{ mt: 2, mb: 4 }}>
                <TextField
                  id="search"
                  type="search"
                  label="Search School"
                  value={searchTerm}
                  onChange={handleChange}
                  sx={{ width: 400 }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <AiOutlineSearch className="searchicon" />
                      </InputAdornment>
                    ),
                  }}
                />
              </Container>
            </div>
          </div>
          <div className="row">
            {filteredClientData.length > 0
              ? filteredClientData?.map((item, index) => {
                  return (
                    <div className="col-xl-4 col-lg-4 col-md-6  " key={index}>
                      <div
                        className="ourclient-content mgt-30 card-section d-flex"
                        data-aos-once={true}
                        data-aos="fade-right"
                        onClick={() => changepage(item)}
                      >
                        <img src={imgdomain + item.logo} alt="img" />
                        <div className="client-details  text-init ">
                          <h2 className="content">{item.school_name}</h2>
                          <p className="label-text">{item.address}</p>
                        </div>
                      </div>
                    </div>
                  );
                })
              : clientdata?.map((item, index) => {
                  return (
                    <div className="col-xl-4 col-lg-4 col-md-6  " key={index}>
                      <div
                        className="ourclient-content mgt-30 card-section d-flex"
                        data-aos-once={true}
                        data-aos="fade-right"
                        onClick={() => changepage(item)}
                      >
                        <img src={imgdomain + item.logo} alt="img" />
                        <div className="client-details  text-init ">
                          <h2 className="content">{item.school_name}</h2>
                          <p className="label-text">{item.address}</p>
                        </div>
                      </div>
                    </div>
                  );
                })}
          </div>
        </div>
        <Demo
          title="We'd love to demonstrate what we can do for you"
          btn_text="Request Demo"
          btn_url="/demo"
        />
      </div>
      {/* {openmodel && <Popup closeMode={closeMode} schoolname={schoolname} schoollogo={schoollogo} schooladdress={schooladdress}  />} */}
    </>
  );
}

export default OurClient;
