import React from 'react'
import { useHistory } from 'react-router-dom'

function FloatingAdmissionForm() {
  const history = useHistory();
  const changepages = () => {
    history.push("/schoollist");
  };
  return (
    
       <div className="pop ">
              <button
                className="addmission_button"
                onClick={() => changepages()}
              >
                Apply for Admission
              </button>
            </div>

  )
}

export default FloatingAdmissionForm
