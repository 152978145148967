import React, { useEffect, useState } from "react";
import AdimssionFormHead from "../AdmissionForm/AdmissionFormHead/AdimssionFormHead";
import { Preview } from "../Dropzone/Dropzone";
import "./Declaration.scss";
import { useAdmissionFormFormikContext } from "../HorizontalStepper.js/FormikWrapper";
import { object } from "yup";
import { event } from "jquery";

export const Declaration = ({ schoolName, address, logo, email, contact }) => {
  const { setFieldValue, values } = useAdmissionFormFormikContext();

  const tempSelectedOption = Object.keys(values?.images?.pdfs)[0];
  const tempFiles = tempSelectedOption && tempSelectedOption.replace(/"/g, "");

  const fileUploadFieldObj = {
    selectedOption: tempSelectedOption || "",
    files: (tempFiles && values?.images?.pdfs?.tempFiles) ?? [],
  };
  const [signature, setSignature] = useState();
  const [stdimage, setStdImg] = useState();
  const [fileUploadField, setFileUploadField] = useState([fileUploadFieldObj]);
  const [fileNames, setFileNames] = useState([{ name: "" }]);

  useEffect(() => {
    setFieldValue("images.student_signature", signature);
  }, [signature]);

  useEffect(() => {
    setFieldValue("images.student_image", stdimage);
  }, [stdimage]);

  const handleUpload = (event, index) => {
    if (event.target.files) {
      const imgfile = Array.prototype.slice.call(event.target.files);
      fileUploadField[index].files.push(imgfile[0]);
    }
  };

  const handleSelectChange = (index, event) => {
    const value = event.target.value;
    const cloned = { ...values.images };
    cloned.pdfs[index].fileType = value;
    setFieldValue("images", cloned);
  };
  const handleFileUpload = (index, event) => {
    const cloned = { ...values.images };
    const imgfile = Array.prototype.slice.call(event.target.files);
    let temp = [];
    temp.push(imgfile[0]);
    cloned.pdfs[index].file = temp;
    setFieldValue("images", cloned);
  };

  const handleFiledelete = (index) => {
    const cloned = { ...values.images };
    cloned.pdfs[index].file = [];
    setFieldValue("images", cloned);
  };

  useEffect(() => {
    const findBirthCertificate = fileUploadField.find(
      (item) => item.selectedOption === "birth_certificate"
    );

    const findCharacterCertificate = fileUploadField.find(
      (item) => item.selectedOption === "character_certificate"
    );
    const findApplicatinLetter = fileUploadField.find(
      (item) => item.selectedOption === "application_letter"
    );
    const findOthers = fileUploadField.find(
      (item) => item.selectedOption === "others"
    );
    setFieldValue("images.pdfs.others", findOthers?.files);
    setFieldValue(
      "images.pdfs.application_letter",
      findApplicatinLetter?.files
    );
    setFieldValue(
      "images.pdfs.character_certificate",
      findCharacterCertificate?.files
    );
    setFieldValue("images.pdfs.birth_certificate", findBirthCertificate?.files);

    const findItem = fileUploadField.find(
      (item) => item.selectedOption === "others"
    );
  }, [fileUploadField]);

  useEffect(() => {
    if (values.images.student_image?.length > 0) {
      setStdImg(values.images.student_image);
    }
  }, [values.images.student_image]);

  useEffect(() => {
    if (values.images.student_signature?.length > 0) {
      setSignature(values.images.student_signature);
    }
  }, [values.images.student_signature]);


  const addSection = () => {
    setFileUploadField([...fileUploadField, fileUploadFieldObj]);
  };

  // character_certificate: "",
  // application_letter: "",
  // others: "",

  return (
    <div className="declaration-info">
      <div className="section-gap">
        <div className="container">
          <div className="admission-form">
            <AdimssionFormHead
              schoolName={schoolName}
              address={address}
              logo={logo}
              email={"email"}
              contact={contact}
            />
            <div className="option-head">
              <div className="uppercase small-title center">
                Declaration and Signature
              </div>
            </div>

            <div className="declaration-detail">
              <ol>
                <li>
                  All the information provided in this application form is
                  correct, complete and true to the best of my knowledge and
                  belief.
                </li>
                <li>
                  I understand that the school reserves the right to cancel the
                  application and subsequent admission on the basis of incorrect
                  or incomplete information I may have furnished.
                </li>

                <li>
                  I shall abide by the rules and regulationss of the school.
                </li>
              </ol>
            </div>

            <div className="declaration-img">
              <div className="row">
                <div className="col-md-6">
                  <div className="image-upload">
                    <p>Student Image:(150*200px)</p>
                    <Preview setFilesList={setStdImg} fileImg={stdimage} />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="image-upload">
                    <p>Student Signature (300*130px)</p>
                    <Preview setFilesList={setSignature} fileImg={signature} />
                  </div>
                </div>
              </div>
              <p>Upload your necessary documents/files here</p>
              {values.images.pdfs?.map((item, index) => {
                return (
                  <div className="file-upload">
                    <select
                      className="field-input"
                      // value={item.selectedOption}
                      value={values.images.pdfs[index].fileType || ""}
                      onChange={(e) => handleSelectChange(index, e)}
                    >
                      <option value="" disabled>
                        --Select--
                      </option>
                      <option value="birth_certificate">
                        Birth Certificate
                      </option>
                      <option value="character_certificate">
                        Character Certificate
                      </option>
                      <option value="application_letter">
                        Applicatiion letter
                      </option>
                      <option value="others">Others</option>
                    </select>
                    {!values?.images?.pdfs?.[index]?.file?.[0]?.name ? (
                      <input
                        type="file"
                        id="file"
                        name="pdfs[]"
                        onChange={(event) => {
                          handleFileUpload(index, event);
                        }}
                      />
                    ) : (
                      <div className="pdf">
                        <p>{values?.images?.pdfs?.[index]?.file?.[0]?.name}</p>
                        <div className="trash-icon">
                          <i
                            class="bi bi-x"
                            onClick={(e) => {
                              e.preventDefault();
                              handleFiledelete(index);
                            }}
                          ></i>
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
              <div className="text-end" onClick={addSection}>
                <button className="btn btn-primary">Add</button>
              </div>
            </div>
            <div className="alert alert-secondary mt-2">
              <div className="declaration-footer">
                <i class="bx bxs-info-circle"></i>
                <p>Please click on the image to upload or change it.</p>
              </div>
              <p>
                File size must be max 5MB (for images) and 2MB (for pdf) each.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
