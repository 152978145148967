import React from "react";
import Banner from "../../components/Banner/Banner";
import TermOfUseComponent from "../../components/TermOfUse/TermOfUseComponent";
const TermOfUsePage = () => {
  // window.scrollTo(0, 0);
  return (
    <>
      <div className="banner-half">
        <Banner
          type="show"
          page="Term of Use"
          title="Term of Use"
          subTitle="NIVID Term of Use"
        />
      </div>
      <TermOfUseComponent />
    </>
  );
};
export default TermOfUsePage;
