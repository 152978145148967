import React from "react";
import Banner from "../Banner/Banner";
import FAQs from "../FAQs/FAQs";
import Demo from "../Demo/Demo";
function FAQPage() {
  return (
    <div>
      <div className="banner-half">
        <Banner
          type="Faqs"
          title="Do you have any questions?"
          subTitle="We’ve put together some frequently asked questions to give you more information about NIVID."
        />
      </div>

      <FAQs type="show" />

<Demo title="We'd love to demonstrate what we can do for you" btn_text="Request Demo" btn_url="/demo" />
    </div>
  );
}

export default FAQPage;
