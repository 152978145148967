import Axios, { domain } from "../Api/AxiosInstance";

export const getFaqs = () => {
  return Axios.get("faqs");
};

export const getFeatures = () => {
  return Axios.get("features");
};
export const getBenefits = () => {
  return Axios.get("benefits");
};

export const getTestimonials = () => {
  return Axios.get("testamonials");
};

export const getSubFeatures = (slug) => {
  return Axios.get(`features/${slug}`);
};

export const getClients = () => {
  return Axios.get("ourclient");
};
export const getSchoolInfo = (slug) => {
  return Axios.get(`${domain}/school_detail/${slug}`);
};
export const getProvince = () => {
  return Axios.get(`axios/province_list`);
};
export const getDistrict = (provinceid) => {
  return Axios.get(`axios/filter_province?province=${provinceid}`);
};
export const getMunicipality = (districid) => {
  return Axios.get(`axios/filter_district?district=${districid}`);
};
// export const getCurrProvince = () => {
//   return Axios.get(`axios/province_list`);
// };
// export const getCurrDistrict = (currprovinceid) => {
//   return Axios.get(`axios/filter_province?province=${currprovinceid}`);
// };
// export const getCurrMunicipality = (currdistricid) => {
//   return Axios.get(`axios/filter_district?district=${currdistricid}`);
// };
export const getSchoolList = () => {
  return Axios.get(`axios/school_types`);
};
export const postSearchlist = (data) => {
  return Axios.post(`axios/filter_school_data`,data)
}
