import React from "react";
import "./MissionVison.scss";
function MissionVison() {
  return (
    <div>
      <div className="missionVision-section section-top">
        <div className="container">
          <h2 className="main-title">Mission, Vision & Objective</h2>
          <div className="content-style"></div>

          <div className="missionV-content">
            <div className="row">
              <div className="col-md-6 col-lg-4">
                <div className="missionV-col">
                  <div className="ben-icon b-1">
                    <img
                      src={require("../../images/icons/mission.svg").default}
                      alt="icon"
                    />
                  </div>
                  <div className="missionV-sub-content">
                    <h2 className="title-content">Mission</h2>
                    <p className="content">
                      Our mission is to bring transformation, innovation and
                      revolution in the education sector of Nepal with the use
                      of top-notch technology-based information management
                      system to ensure quality education
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-lg-4">
                <div className="missionV-col">
                  <div className="ben-icon b-2">
                    <img
                      src={require("../../images/icons/vision.svg").default}
                      alt="icon"
                    />
                  </div>
                  <div className="missionV-sub-content">
                    <h2 className="title-content">Vision</h2>
                    <p className="content">
                      To digitalize, systematize and synchronize the data and
                      information for the educational institutions for their
                      effective management and delivery of their systems and
                      services.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-lg-4">
                <div className="missionV-col">
                  <div className="ben-icon b-3">
                    <img
                      src={require("../../images/icons/obj.svg").default}
                      alt="icon"
                    />
                  </div>
                  <div className="missionV-sub-content">
                    <h2 className="title-content">Objective</h2>
                    <p className="content">
                      We aim to transform from the traditionally managed system
                      to a swift, innovative and efficient ICT-based system.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MissionVison;
