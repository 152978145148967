import React from "react";
import { logAnalyticEvent, snakeCase } from "../../firebaseconfig";
import "./Demo.scss";
import { Link } from "react-router-dom";

function Demo(props) {
  logAnalyticEvent("demo_requested");
  return (
    <div className="demo-section section-top">
      <div className="container">
        <div className="demo-content">
          <div className="row">
            <div className="col-md-5">
              <h2 className="main-title">{props.title}</h2>
            </div>
            <div className="col-md-4">
              <img src={require("../../images/Group.png").default} alt="img" />
            </div>
            <div className="col-md-3">
              <div className="request-demo">
                <img
                  src={require("../../images/shine.png").default}
                  alt="img"
                />
                <Link to={props.btn_url}>
                  <button
                    className="main-btn"
                    onClick={() => logAnalyticEvent(snakeCase("request_demo"))}
                  >
                    {props.btn_text}
                  </button>
                </Link>
                <img
                  className="shine"
                  src={require("../../images/shine.png").default}
                  alt="img"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Demo;
