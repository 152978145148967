import React from "react";
import "./ZoomIntegrationComponent.scss";
const ZoomIntegrationComponent = () => {
  return (
    <div className="zoom-integration-section ">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="document section">
              <h4 className="zoom-header">
                NIVID Online Class Integrated with Zoom and Google Meet
              </h4>
              <p>
                <span className="bold">NIVID</span> is a{" "}
                <span class="bold">
                  School Information Management Software (SIMS)
                </span>{" "}
                developed by <span class="bold">Axios Softwork Pvt. Ltd.</span>{" "}
                We, being an emerging Nepalese IT company, aim to make optimum
                use of technology so as to digitize and automate the system for
                the overall progress of the country.
              </p>
              <p>
                NIVID school management software manages zoom meetings for
                teachers and students/parents. Automatic attendance of teachers
                and students as soon as they join the zoom class. The process is
                simple and admin can create the meeting for their specific grade
                and classroom. Simply, the teacher can join the meeting as per
                the schedule.
              </p>
              <p>
                Initially, students can join the meetings through their web
                portal by clicking on the join button or students will get a URL
                notification on their app as a second option.
              </p>

              <hr />
              <p className="text-center">Installation </p>
              <hr />

              <p>
                <span className="bold">Signing</span> is the first step to get
                the benefits provided by NIVID{" "}
              </p>
              <p>
                <span className="dash">-</span>Visit School portal website
              </p>
              <p>
                <span className="dash">-</span>Enter the login credentials
                provided by the school and click on the login button.
              </p>
              <img
                className="zoom-img"
                src={require("../../images/form.png").default}
                alt=""
                srcset=""
                width="100%"
                height="650px"
              />

              <p>
                <span className="dash">-</span> After successful login, you can
                see the users icon on the top right corner.
              </p>
              <img
                className="zoom-img"
                src={require("../../images/class.png").default}
                alt=""
                srcset=""
                width="100%"
                height="650px"
              />
              <p>
                <span className="dash">-</span>Click on users icon and select
                connect zoom option
              </p>
              <img
                className="zoom-img"
                src={require("../../images/user.png").default}
                alt=""
                srcset=""
                width="100%"
                height="650px"
              />

              <p>
                <span className="dash">-</span>You will be redirected to a page
                like this.
              </p>
              <img
                className="zoom-img"
                src={require("../../images/install.png").default}
                alt=""
                srcset=""
                width="100%"
                height="680px"
              />

              <p>
                <span className="dash">-</span>Click on the Authorize button to
                complete the authorization
              </p>
              <p>
                <span className="dash">-</span>After successful authorization,
                you will be redirected to the NIVID online class dashboard and
                that’s all.
              </p>
              <p>
                <span className="dash">-</span>Further process click on the plus
                sign to create a class
              </p>
              <img
                className="zoom-img"
                src={require("../../images/class.png").default}
                alt=""
                srcset=""
                width="100%"
                height="650px"
              />

              <p>
                <span className="dash">-</span>Add class subject Date and
                time.you will get two option google meet and zoom select zoom
              </p>
              <img
                className="zoom-img"
                src={require("../../images/teacher-select.png").default}
                alt=""
                srcset=""
                width="100%"
                height="650px"
              />
              <img
                className="zoom-img"
                src={require("../../images/teacher.png").default}
                alt=""
                srcset=""
                width="100%"
                height="650px"
              />
              <p>
                <span className="dash">-</span>Then click on zoom class{" "}
              </p>

              <hr />
              <p className="text-center">Uninstallation</p>
              <hr />

              <p>
                <span className="dash">1.</span>Login to your Zoom account and
                navigate to Zoom App Marketplace.
              </p>
              <p>
                <span className="dash">2.</span>Click Manage Installed Apps or
                search for the NIVID app.
              </p>
              <p>
                <span className="dash">3.</span>Click on the NIVID app.
              </p>
              <p>
                <span className="dash">4.</span>Click Uninstall.
              </p>

              <div className="black">
                <h1 className="text-center">Thank You !!</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ZoomIntegrationComponent;
