import React from "react";
import Banner from "../Banner/Banner";
import QualityFeatures from "../QualityFeatures/QualityFeatures";
import Demo from "../Demo/Demo";
import MetaConfigure from "../helmet/helmet";
import { useEffect } from "react";

function FeaturePage() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <div>
      <div className="banner-half">
        <MetaConfigure title="Optimum use of technology to digitize and automate the school management systems" />

        <Banner
          type="show"
          page="Features"
          title="Meet exciting feature of app"
          subTitle="Automation of the daily activities by tracking of real time progress"
        />
      </div>
      <QualityFeatures type="featureAll" />
      <Demo
        title="We'd love to demonstrate what we can do for you"
        btn_text="Request Demo"
        btn_url="/demo"
      />
    </div>
  );
}

export default FeaturePage;
