import React, { useState } from "react";
import { logAnalyticEvent, snakeCase } from "../../firebaseconfig";
import "./Contact.scss";
import { Contactform } from "../Api/PostApi";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";

function Contact(props) {
  const [buttonDisable, setButtonDisable] = useState(false);
  const [preLoader, setPreLoader] = useState(false);

  const successNotify = () => {
    toast.success("Form Submitted Sucessfully");
  };

  const errorNotify = () => {
    toast.error("Something Wrong!!");
  };

  useEffect(() => {
    window.scroll(0, 0);
  });
  return (
    <div className="contact-section text-init ">
      <ToastContainer />
      <div className="container">
        <div className="row">
          <div className="col-md-8">
            {props?.header === "true" ? (
              <div>
                <h2
                  className="main-title"
                  data-aos-once={true}
                  data-aos="fade-down"
                >
                  Let's Talk
                </h2>
                <div
                  className="content-style"
                  data-aos-once={true}
                  data-aos="fade-left"
                ></div>

                <p
                  className="content"
                  data-aos-once={true}
                  data-aos="fade-right"
                >
                  We’d love to hear form you. Send us your question or comment
                  with the form below and We’ll be in touch with you as soon as
                  possible.
                </p>
              </div>
            ) : (
              ""
            )}

            <Formik
              initialValues={{
                name: "",
                number: "",
                email: "",
                subject: "",
                message: "",
              }}
              validationSchema={Yup.object({
                name: Yup.string().required("Full name is required"),
                number: Yup.string()
                  .min(9, "Phone number is too short!")
                  .max(10, "Phone number is too long!")
                  .required("Phone number is Required"),
                email: Yup.string().required("Email address is required"),
                subject: Yup.string().required("Subject must be required"),
                message: Yup.string().required("Message must be required"),
              })}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                setPreLoader(true);
                setButtonDisable(true);
                const postData = new FormData();
                postData.append("name", values.name);
                postData.append("number", values.number);
                postData.append("email", values.email);
                postData.append("subject", values.subject);
                postData.append("message", values.message);
                setPreLoader(true);
                Contactform(postData).then((res) => {
                  if (res.status === 200) {
                    successNotify();
                    setButtonDisable(false);
                    resetForm();
                  } else {
                    errorNotify();
                  }
                  setSubmitting(false);
                });
              }}
            >
              <Form data-aos-once={true} data-aos="fade-right">
                <div className="input-field">
                  <p>
                    Full Name <i className="required-input">*</i>
                  </p>

                  <Field
                    className="form-control text-capitalize"
                    name="name"
                    type="text"
                    placeholder="Enter your fullname"
                    pattern="^[a-zA-Z]{4,}(?: [a-zA-Z]+){0,2}$"
                  />
                  <span className="error">
                    <ErrorMessage name="name" />
                  </span>
                </div>

                <div className="input-field">
                  <div className="row">
                    <div className="col-md-12">
                      <p>
                        Email Address <i className="required-input">*</i>
                      </p>
                      <Field
                        className="form-control"
                        name="email"
                        placeholder="Enter your email"
                        type="email"
                      />
                      <span className="error">
                        <ErrorMessage name="email" />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="input-field">
                  <div className="col-md-12 ">
                    <p>
                      Phone Number <i className="required-input">*</i>
                    </p>
                    <Field
                      className="form-control"
                      name="number"
                      placeholder="Enter your number"
                      type="number"
                      pattern="^[0-9]*$"
                      maxlength="10"
                    />
                    <span class="error">
                      <ErrorMessage name="number" />
                    </span>
                  </div>
                </div>

                <div className="input-field">
                  <p>
                    Subject <i className="required-input">*</i>
                  </p>
                  <Field
                    className="form-control"
                    name="subject"
                    type="text"
                    placeholder="Enter your subject"
                  />
                  <span class="error">
                    <ErrorMessage name="subject" />
                  </span>
                </div>

                <div className="input-field">
                  <p>
                    Message <i className="required-input">*</i>
                  </p>
                  <Field
                    as="textarea"
                    className="form-control"
                    name="message"
                    placeholder="Enter your message"
                    type="text"
                  />
                  <span className="error">
                    <ErrorMessage name="message" />
                  </span>
                </div>
                <button
                  disabled={buttonDisable}
                  type="submit"
                  className="main-btn"
                  onClick={() => logAnalyticEvent(snakeCase("Send_Message"))}
                >
                  Send Message{" "}
                  {preLoader ? (
                    <img
                      className="spin-size"
                      src={require("../../images/feature-img/spin.gif").default}
                      alt="img"
                    />
                  ) : (
                    ""
                  )}
                </button>
              </Form>
            </Formik>
          </div>
          <div className="col-md-4">
            <img
              className="contact-img"
              src={require("../../images/contact.svg").default}
              alt="img"
              data-aos-once={true}
              data-aos="fade-down"
            />
            <div
              className="contact-info"
              data-aos-once={true}
              data-aos="fade-left"
            >
              <div className="d-flex ">
                <i class="bi bi-geo-alt"></i>
                <p className="content">Banepa kavre,Nepal</p>
              </div>
              <div className="d-flex ">
                <i class="bi bi-telephone"></i>
                <p className="content">
                  <a href="tel:9866003444">9866003444</a>
                </p>
              </div>
              <div className="d-flex ">
                <i class="bi bi-envelope"></i>
                <p className="content">
                  <a href="mailto:nividaxios@gmail.com" targets="_blank">
                    nividaxios@gmail.com
                  </a>
                </p>
              </div>

              <div className="d-flex social-media">
                <a href="https://www.facebook.com/nivid.app.3">
                  <img
                    src={require("../../images/Facebook.png").default}
                    alt="img"
                  />
                </a>

                {/* <img
                  src={require("../../images/Instagram.png").default}
                  alt="img"
                /> */}
                <a
                  href="https://www.linkedin.com/company/nivid"
                  targets="_blank"
                >
                  <img
                    src={require("../../images/linkedin.png").default}
                    alt="img"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
