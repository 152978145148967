import React from "react";
import AboutDetails from "../AboutDetails/AboutDetails";
import Banner from "../Banner/Banner";
import Benefits from "../Benefits/Benefits";
import Count from "../Count/Count";
import History from "../History/History";
import MissionVison from "../MissionVision/MissionVison";
import Demo from "../Demo/Demo";
import MetaConfigure from "../helmet/helmet";
import { useEffect } from "react";

function Aboutus() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <div className="about-section">
      <MetaConfigure title="NIVID makes the entire school management system easier" />

      <div className="banner-half">
        <Banner
          type="show"
          page="About us"
          title="NIVID is a complete platform "
          subTitle="Web- based SMS platform for the entire school community"
        />
      </div>
      <AboutDetails />
      <MissionVison />

      <Benefits />
      <History />
      <Count />
      <Demo
        title="We'd love to demonstrate what we can do for you"
        btn_text="Request Demo"
        btn_url="/demo"
      />
    </div>
  );
}

export default Aboutus;
