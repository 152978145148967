import React, { useEffect, useState } from "react";
import Banner from "../Banner/Banner";
import "./SubFeature.scss";
import { getSubFeatures } from "../Api/GetApi";
import { imgdomain } from "../Api/AxiosInstance";
import { useParams } from "react-router";

function SubFeature() {
  // window.scrollTo(0, 0);
  const { slug } = useParams();
  const [datas, setDatas] = useState({});
  useEffect(() => {
    getSubFeatures(slug).then((res) => {
      setDatas(res.data);
    });
    // eslint-disable-next-line
  }, []);

  return (
    <div className="subFeature-section">
      <div className="banner-half">
        <Banner
          type="show"
          title="Features"
          subTitle="NIVID School Management System"
        />
      </div>
      <div className="container">
        <div className="subFeature-content mgt-30">
          <div className="row">
            <div className="col-md-8">
              <h1 className="main-title">{datas?.features?.title}</h1>
              <p>{datas?.features?.desc}</p>
            </div>
            <div className="col-md-4 position-r">
              <img
                src={imgdomain + datas?.features?.banner}
                alt="img"
                className="position-a-lg"
              />
            </div>
          </div>
        </div>

        <div className="featureMark section-top">
          <h2 className="main-title">Other Features</h2>
          <div className="content-style"></div>
          <div className="row">
            {datas?.sub_features?.map((item, index) => {
              return (
                <div className="col-md-6">
                  <div
                    className="featureMark-content mgt-30 d-flex"
                    key={index}
                  >
                    <div
                      className="sub-feature-icon "
                      style={{
                        backgroundColor: `${
                          item.color ? `${item.color}` : "#29abe21a"
                        }`,
                      }}
                    >
                      {imgdomain + item.image ===
                      "https://storage.googleapis.com/axios-documents/null" ? (
                        <img
                          src={require("../../images/icons/trophy.svg").default}
                          alt="icon"
                        />
                      ) : (
                        <img src={imgdomain + item.image} alt="icon" />
                      )}
                    </div>

                    {/* <img src={imgdomain + item.image} alt="img" /> */}
                    <div className="feature-details text-init">
                      <h1 className="main-title">{item.title}</h1>
                      <p className="content">{item.description}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubFeature;
