import React, { useEffect } from "react";
import { HorizontalStepper } from "../HorizontalStepper.js/HorizontalStepper";
import { PersonalInfo } from "../PersonalInfo/PersonalInfo";
import { ParentsInfo } from "../ParentInfo/ParentInfo";
import "./AdmissionForm.scss";
import { useStepper } from "react-usestepper";
import { StepperBottom } from "../StepperBottom/StepperBottom";
import { ContactInfo } from "../ContactInfo/ContactInfo";
import { useHistory, useLocation } from "react-router-dom";
import nivid from "../../images/nivid-logo-min.png";

import {
  FormikWrapper,
  useAdmissionFormFormikContext,
} from "../HorizontalStepper.js/FormikWrapper";
// import { useToast } from "../context/toast/toast";
import { useToast } from "../Context/toast";
import { toast, ToastContainer } from "react-toastify";
import { OtherInfo } from "../OtherInfo/OtherInfo";
import { Declaration } from "../Declaration/Declaration";
import PreviewDetails from "../Previewdetails/PreviewDetails";

const Footer = () => {
  const { handleNext, currentStep, handlePrevious, isLastStep } = useStepper(
    {}
  );
  const { values, handleSubmit, isSubmitting, errors } =
    useAdmissionFormFormikContext();
  // const { showErrorMessage } = useToast();
  // console.log(values);
  const { search } = useLocation();

  const params = new URLSearchParams(search);
  const history = useHistory();
  const decodedschooldetails = params.get(`id`);
  const decodedschooldetail = atob(decodedschooldetails);
  const newdecodedschooldetail = JSON.parse(decodedschooldetail);
 
  const subDomain= newdecodedschooldetail.subdomain;



    // console.log("subdomaintest",subDomain)

  const validFile = (a) => {
    for (let i = 0; i < a.length; i++) {
      if (a[i].file.length <= 0) {
        return false;
      }
    }
    return true;
  };

  const handleNextClick = () => {
    if (isLastStep) {
      handleSubmit();
      return;
    }
    if (currentStep === 1) {
      if (!values["grade_token"]) {
        toast.error("Please Select Grade");
        return;
      }
      if (!values["student_name"]) {
        toast.error("Please Enter First Name");
        return;
      }
      if (!values["lastName"]) {
        toast.error("Please enter Last Name");
        return;
      }

      if (!values["dob"]) {
        toast.error("Please enter Date of Birth");
        return;
      }
      if (values["age"] <= 2) {
        toast.error("Age must be more then two years");
        return;
      }
      if (!values["ADdob"]) {
        toast.error("Please enter dob in AD");
        return;
      }
      if (!values["gender"]) {
        toast.error("Please Choose Your Gender");
        return;
      }
      if (!values["email"]) {
        toast.error("Please enter you Email Address");
        return;
      }
      if (!values["email"].match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
        toast.error("Please enter a valid email address");
        return;
      }
      if (!values["mobile"]) {
        toast.error("Please enter your mobile no.");
        return;
      }
      if (!values["ethnicity"]){
        toast.error("Please choose your Caste / Ethnicity");
        return;
      }

      // if (values["phone"].toString().length < 10) {
      //   toast.error("Phone number must be 10 characters or more");
      //   return;
      // }
      handleNext();
    }
    if (currentStep === 2) {
      // if (!values["father_name"]) {
      //   toast.error("Please enter Father's Name");
      //   return;
      // }
      // if (values["father_number"].toString().length < 10) {
      //   toast.error("Father Phone number must be 10 characters");
      //   return;
      // }
      // if (!values["mother_name"]) {
      //   toast.error("Please enter Mother's Name");
      //   return;
      // }
      // if (values["mother_number"].toString().length < 10) {
      //   toast.error("Mother Phone number must be 10 characters");
      //   return;
      // }
      handleNext();
    }

    if (currentStep === 3) {
      if (!values["state"]) {
        toast.error("Please Select State");
        return;
      }
      if (!values["district"]) {
        toast.error("Please Select District");
        return;
      }
      if (!values["municipality"]) {
        toast.error("Please Select Municipality");
        return;
      }
      if (!values["ward_no"]) {
        toast.error("Please Input Ward Number");
        return;
      }
      handleNext();
    }

    if (currentStep === 4) {
      if (!values["transport"]) {
        toast.error("Please select transportation");
        return;
      }

      if (!values["canteen"]) {
        toast.error("Please select canteen");
        return;
      }

      if (!values["hostel"]) {
        toast.error("Please select hostel");
        return;
      }
      if (subDomain === "urbana") {

        if (!values["stream"]) {
          toast.error("Please select Stream");
          return;
        }
      }
      if (!values["previous_school"]) {
        toast.error("Please enter Previous School Name");
        return;
      }
      if (!values["previous_gpa"]) {
        toast.error("Please enter your Previous GPA");
        return;
      }
      

      // if (values["refferal_contact"] < 10) {
      //   toast.error("Phone number must be 10 characters or more");
      //   return;
      // }
      // if (values["number2"] < 10) {
      //   toast.error("Phone number must be 10 characters or more");
      //   return;
      // }
      handleNext();
    }
    if (currentStep === 5) {
      if (
        values?.images?.student_image.length <= 0 &&
        values?.images?.student_signature.length <= 0
      ) {
        toast.error("please upload the student image");
        return;
      }

      // if (!validFile(values.images.pdfs)) {
      //   toast.error("Please upload one  document");
      //   return;
      // }

      // if (
      //   Object.keys(values?.images?.pdfs).find((f) => f === "birth_certificate")
      // ) {
      //   if (values?.images?.pdfs?.birth_certificate.length <= 0) {
      //     toast.error("Please upload birth_certificate");
      //     return;
      //   }
      // }
      // if (
      //   Object.keys(values?.images?.pdfs).find(
      //     (f) => f === "character_certificate"
      //   )
      // ) {
      //   if (values?.images?.pdfs?.character_certificate.length <= 0) {
      //     toast.error("Please upload character_certificate");
      //     return;
      //   }
      // }
      // if (
      //   Object.keys(values?.images?.pdfs).find(
      //     (f) => f === "application_letter"
      //   )
      // ) {
      //   if (values?.images?.pdfs?.application_letter <= 0) {
      //     toast.error("Please upload application_letter");
      //     return;
      //   }
      // }

      handleNext();
    }

    handleNext();
  };
  return (
    <StepperBottom
      backText="Go to Prev"
      cancelText="Cancel"
      nextText={isLastStep ? "Save" : "Go to Next"}
      onBack={handlePrevious}
      onNext={handleNextClick}
      disableNext={isSubmitting}
      showBackButton={currentStep !== 1}
    />
  );
};

export const AdmissionForm = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  // const schoolName = params.get("school_name");
  // const address = params.get("address");
  // const logo = params.get("logo");
  // const email1 = params.get("email1");
  // const email2 = params.get("email2");
  // const contact1 = params.get("contact1");
  // const contact2 = params.get("contact2");
  const history = useHistory();
  const decodedschooldetails = params.get(`id`);
  const decodedschooldetail = atob(decodedschooldetails);
  const newdecodedschooldetail = JSON.parse(decodedschooldetail);
  const schoolName = newdecodedschooldetail.school_name;
  const address = newdecodedschooldetail.address;
  const logo = newdecodedschooldetail.logo;
  const email1 = newdecodedschooldetail.email1;
  const email2 = newdecodedschooldetail.email2;
  const contact1 =newdecodedschooldetail.contact1;
  const contact2 = newdecodedschooldetail.contact2;
  const subdomain= newdecodedschooldetail.subdomain;


  
  const stepperConfig = [
    {
      title: "Student's Information",
      icon: <i class="bx bxs-user"></i>,
      body: (
        <>
          <PersonalInfo
            schoolName={schoolName}
            address={address}
            logo={logo}
            email1={email1}
            email2={email2}
            contact1={contact1}
            contact2={contact2}
            subdomain={subdomain}
          />
        </>
      ),
    },
    {
      title: "Parent's Information",
      icon: <i class="bx bx-male-female"></i>,
      body: (
        <>
          <ParentsInfo
            schoolName={schoolName}
            address={address}
            logo={logo}
            email1={email1}
            email2={email2}
            contact1={contact1}
            contact2={contact2}
          />
        </>
      ),
    },
    {
      title: "Contact Address",
      icon: <i class="bx bxs-home"></i>,
      body: (
        <>
          <ContactInfo
            schoolName={schoolName}
            address={address}
            logo={logo}
            email1={email1}
            email2={email2}
            contact1={contact1}
            contact2={contact2}
          />
        </>
      ),
    },
    {
      title: "Other Information",
      icon: <i class="bx bx-dots-horizontal-rounded"></i>,
      body: (
        <>
          <OtherInfo
            schoolName={schoolName}
            address={address}
            logo={logo}
            email1={email1}
            email2={email2}
            contact1={contact1}
            contact2={contact2}
            subdomain={subdomain}
          />
        </>
      ),
    },
    {
      title: "Declaration & Signature",
      icon: <i class="bx bxs-image-alt"></i>,
      body: (
        <>
          <Declaration
            schoolName={schoolName}
            address={address}
            logo={logo}
            email1={email1}
            email2={email2}
            contact1={contact1}
            contact2={contact2}
          />
        </>
      ),
    },
    {
      title: "Preview & Post",
      icon: <i class="bx bxs-image-alt"></i>,
      body: (
        <>
          <PreviewDetails
            schoolName={schoolName}
            address={address}
            logo={logo}
            email1={email1}
            email2={email2}
            contact1={contact1}
            contact2={contact2}
          />
        </>
      ),
    },
  ];

  return (
    <div className="admissionform">
      <div className="navbar">
        <button
          onClick={() => {
            history.goBack();
          }}
        >
          <i class="bi bi-arrow-bar-left">Back</i>
        </button>

        {/* <a className="navbar-brand" href="/"> */}

        <img
          className="aimg"
          width="120px"
          src={nivid}
          onClick={() => {
            history.push("/");
          }}
        />

        {/* </a> */}
      </div>
      <FormikWrapper>
        <div className="admission-form-section">
          <ToastContainer />
          <HorizontalStepper config={stepperConfig} Footer={<Footer />} />
        </div>
      </FormikWrapper>
    </div>
  );
};
